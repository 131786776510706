// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "[dir] .chartWrapper--1pFgd {\n    margin: 30px auto;\n    padding: 0;\n}\n\n.canvasWrapper--1TiBm {\n    height: 850px;\n}\n\n.loader--11ykX {\n    width: 100%;\n    height: 100%;\n}\n\n.loaderImage--va_WI {\n    width: 100%;\n    height: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"chartWrapper": "chartWrapper--1pFgd",
	"canvasWrapper": "canvasWrapper--1TiBm",
	"loader": "loader--11ykX",
	"loaderImage": "loaderImage--va_WI"
};
module.exports = exports;
