import React from "react";
import styles from "../../styles.css";
import indicatorsStyles from "./styles.css";
import { default as SubIndicators } from "../SubIndicators/index.jsx";

import { isSearchedPhrase } from "../../utils";

export default ({
	indicators,
	nestedListsToShow,
	showNestedLists,
	selectedSubjects,
	onSubjectSelect,
	subjectColor,
	isSimplifiedView,
	subjectParent,
	queryParams,
	searchPhrase,
	onSubjectChange,
}) =>
	indicators.map((indicator, index) => {
		if (
			isSearchedPhrase({
				subjectName: indicator.fullName,
				phrase: searchPhrase,
			}) &&
			!nestedListsToShow.includes(indicator.topLevelParent)
		) {
			nestedListsToShow.push(indicator.topLevelParent);
		}

		let disableExpandButton = false;

		let subjectWithNoChildren = false;

		let subjectsGroup = [];

		const getSubjectChildrens = (childrens) => {
			childrens.forEach((child) => {
				if (child.childrens) {
					getSubjectChildrens(child.childrens);
				}
				subjectsGroup.push(child.id);
			});
		};

		indicator.childrens ? getSubjectChildrens(indicator.childrens) : (subjectWithNoChildren = true);

		subjectsGroup.forEach((children) => {
			if (
				isSearchedPhrase({
					subjectName: children,
					phrase: searchPhrase,
				})
			) {
				disableExpandButton = true;
			}
		});

		return (
			<li
				key={`${indicator.fullName.split(" ").join("")}_${index}`}
				className={`${indicatorsStyles.level3} ${
					!isSimplifiedView && selectedSubjects.includes(indicator.id)
						? `${indicatorsStyles.selectedItem} ${subjectParent}`
						: ""
				}`}
			>
				<a
					onClick={(e) => {
						if (isSimplifiedView && onSubjectChange) {
							e.preventDefault();
							onSubjectChange(indicator.id);
						}
						if (!isSimplifiedView) {
							e.preventDefault();
							onSubjectSelect(indicator.id);
						}
					}}
					href={`/measures/${indicator.id.toLowerCase()}.html`}
					className={`${
						isSearchedPhrase({
							subjectName: indicator.fullName,
							phrase: searchPhrase,
						})
							? styles.highlightSearchedWord
							: ""
					} ${indicatorsStyles.level3Item} ${styles.subjectName} ${
						!isSimplifiedView && selectedSubjects.includes(indicator.id)
							? `${indicatorsStyles.selected} ${subjectParent}`
							: ""
					}`}
					style={
						!isSimplifiedView && selectedSubjects.includes(indicator.id)
							? {
									background: `rgba(${subjectColor}, 0.25)`,
									border: `1px solid rgb(${subjectColor})`,
							  }
							: null
					}
				>
					{indicator.fullName}
				</a>
				{!subjectWithNoChildren ? (
					<span>
						{!nestedListsToShow.includes(indicator.id) ? (
							<span
								className={`${indicatorsStyles.appMenuLevel3Expand}`}
								uk-icon="icon:plus; ratio: 0.75"
								onClick={() => {
									showNestedLists((displayedRows) => [...displayedRows, indicator.id]);
								}}
							></span>
						) : (
							<span
								className={`${indicatorsStyles.appMenuLevel3Expand} ${
									disableExpandButton ? indicatorsStyles.disabled : ""
								}`}
								uk-icon="icon:minus; ratio: 0.75"
								onClick={() => {
									if (disableExpandButton) {
										return;
									}
									showNestedLists((displayedRows) =>
										displayedRows.filter((item) => item !== indicator.id)
									);
								}}
							></span>
						)}
					</span>
				) : null}

				{indicator?.childrens ? (
					<SubIndicators
						subIndicators={indicator?.childrens}
						indicatorId={indicator.id}
						nestedListsToShow={nestedListsToShow}
						selectedSubjects={selectedSubjects}
						onSubjectSelect={onSubjectSelect}
						subjectColor={subjectColor}
						isSimplifiedView={isSimplifiedView}
						subjectParent={subjectParent}
						queryParams={queryParams}
						searchPhrase={searchPhrase}
						onSubjectChange={onSubjectChange}
					/>
				) : null}
			</li>
		);
	});
