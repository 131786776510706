import getUrlQueryParams from '../getUrlQueryParams';
import isCitvoiceMeasure from '../isCitvoiceMeasure';
import getNonCvLevelItems from '../getNonCvLevelItems';
import getCvLevelItems from '../getCvLevelItems';
import getStyleLevelForMeasure from '../getStyleLevelForMeasure';

let appData;

const expandSubjects = (state, subjects) => subjects.reduce((acc, subject) => {
  if (/^\.\d$/.test(subject)) {
    const subjectLevel = subject.split('.')[1];

    getCvLevelItems(state, subjectLevel).forEach((e) => acc.push(e.s_id));

    return acc;
  }
  if (/^_\d$/.test(subject)) {
    const subjectLevel = subject.split('_')[1];

    getNonCvLevelItems(state, subjectLevel).forEach((e) => acc.push(e.s_id));

    return acc;
  }
  acc.push(subject);
  return acc;
}, []);

const sortSubjects = (ela, elb) => {
  const obja = appData.fullListOfMeasures.find((meas) => meas.s_id == ela);
  const objb = appData.fullListOfMeasures.find((meas) => meas.s_id == elb);
  if (
    getStyleLevelForMeasure(appData, obja.s_id)
    != getStyleLevelForMeasure(appData, objb.s_id)
  ) {
    return obja.lvl - objb.lvl;
  }
  const indexa = appData.fullListOfMeasures.findIndex(
    (meas) => meas.s_id == ela,
  );
  const indexb = appData.fullListOfMeasures.findIndex(
    (meas) => meas.s_id == elb,
  );
  return indexa - indexb;
};

const replacementMap = {
  "AbsThreatPhysInt":"AbsViolCiv",
  "EffFightHumTraf":"AbHumTraForcLab",
  "SupCon":"ExecCompRoL",
  "NoDisBefLaw":"AbsDiscJust",
  "NoDisCivJusWJP":"AbsDiscCivJustWJP",
  "NoDisCrimJusWJP":"AbsDiscCrimJustWJP",
  "FunctGov":"AbsUnInfGov",
  "DiscJudInf":"PubLawJudInf",
  "CleanProcuProc":"PubProcuProc",
  "MediaFreeRSF":"PressFreeRSF",
  "AbsDiscrim":"ProtAgDiscrim",
  "PolPowRepGend":"PolPowRepWom",
  "WomRepExecWB":"WomRepExecIPU",
  "WorkGendEqGI":"WorkWomEqGI",
  "AccPubServGendVDEM":"AccPubServWomVDEM",
  "LawsViolenceWomOECD":"LawsViolenceWomWB",
  "TaxCapICTDUNUWIDER":"TaxCapUNUWIDER",
  "ProPubPersGI":"ProPubPers",
  "CompPolicyBS":"CompPolBS",
  "TranspInfr":"TranspNet",
  "RoadInfWEF":"RoadInfAFDB",
  "AccElectrWB":"AccEnergy",
  "AffEqHealthSys":"AccHealth",
  "UnlDeathNCDIHME":"AbsDeathNCDIHME",
  "ContMatMortMMEIG":"ContMatMortUNMMEIG",
  "GendParPrimEducWB":"GendParPrimEducUNESCO",
  "GendParLowSecEducWB":"GendParLowSecEducUNESCO",
  "CompPrimWB":"ComPrimEducUNESCO",
  "BuildHumResAfDB":"WelfPolServ",
  "BuildHumResWB":"WelfPolServ",
  "AbsSlumHousUNHab":"AbsSlumHousUNHABITAT",
  "AbsTreeCovLossWRI":"ConPrimForLossWRI",
  "ProtEcosysEPI":"TerrBioProtEPI",
};

const filterSelectedSubjects = (state, subjects) => {

    subjects = subjects.map((subject) => {
        if (replacementMap[subject]) {
            return replacementMap[subject];
        }
        return subject;
    });

    return subjects.filter((subject) =>{
        if (!subject) return false;
        if (state.fullListOfMeasures.find((measure) => measure.s_id == subject)) {
            return true;
        }
        return false;
    }).filter((subject, index, self) => self.indexOf(subject) === index);

}

export default (state) => {
  appData = state;
  const selectedSubjects = getUrlQueryParams()?.meas?.split('-') || [];

  const expandedSelectedSubjects = expandSubjects(state, selectedSubjects);
  const filteredSelectedSubjects = filterSelectedSubjects(state, expandedSelectedSubjects);

  return filteredSelectedSubjects
    .sort(sortSubjects);
};
