import React from 'react';
import {
  OverviewChildSubject,
  OverviewSingleScore,
} from '../../pages/Data/partials';
import { defaultVariables } from '../../referenceData';
import styles from './styles.css';
import { useModalContext } from '../../context/modalContext.jsx';
import { ClassificationInfo } from '../Modals';
import {useTranslation} from "react-i18next";
import {scoreChangeFormat} from "../../actions";

export default ({ locationData }) => {
  const [showModal] = useModalContext();
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`${styles.wrapper} ${
        locationData.locationPage ? styles.locationPageWrapper : ''
      } overview-main uk-text-center overview-white`}
    >
      <div className="overview-test">
        {!locationData.locationPage ? (
          <div className="overview-country ">
            <div className="overview_headline uk-margin-top uk-margin-large-bottom">
              <h1 className="overview-title">{locationData.headline}
              <img
                src={`/img/flags/${locationData.iso.toLowerCase()}.svg`}
                className={`flag-image flag ${locationData.hideFlag} ${styles.flagIcon} measure-icon-image `}
              />
              </h1>
            </div>
            <div
              onClick={() => {
                window.location.href = locationData.linkToSource;
              }}
              className={`overview-flag ${locationData.hideFlag} `}
            >
              <div>{t('About {{country}}', { country: locationData.headline })}</div>
            </div>
          </div>
        ) : null}

        <div
          className={`uk-flex uk-margin-top overview-flex overview-flex--thirds  ${locationData.subject_level} ${locationData.subject_type} `}
        >
          <OverviewSingleScore
            location={locationData.iso}
            subject={locationData.subject_s_id}
            isLocationPage={locationData.locationPage}
          />
          <hr className="uk-divider-vertical" />
          <div
            id="first_row_2"
            className={`${locationData.hide} ${locationData.first_row_width} uk-panel uk-panel-box-secondary  uk-text-center uk-text-middle`}
          >
            <div className="">
              <h4 className=" uk-width-1-1  overview-bold-title">{t("Ranking")}</h4>
              <div
                className={`percentage-circle circle-red  ${locationData.subject_type} ${locationData.subject_level} `}
                style={{
                  '--color': locationData.subject_color,
                  '--score': Math.max(0, locationData.subject_rank_percent_int),
                }}
              >
                <svg
                  viewBox="0 0 100 100"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    className="circle-back"
                    r="45%"
                    cx="50%"
                    cy="50%"
                    fill="transparent"
                    pathLength="100"
                  ></circle>
                  <circle
                    className="circle-front"
                    r="45%"
                    cx="50%"
                    cy="50%"
                    fill="transparent"
                    pathLength="100"
                  ></circle>
                </svg>
              </div>
              <span className="overview_score uk-width-1-1">
                {locationData.ranking}
              </span>
              <h4 className=" uk-width-1-1 overview-body-copy">
                <span template-mif-string-id="26">{t("Out of")} </span>
                {locationData.countries_with_rank}
              </h4>
            </div>
          </div>
          <hr className={`uk-divider-vertical  ${locationData.hide} `} />
          <div
            className={`${locationData.first_row_width} uk-panel uk-panel-box-secondary  uk-text-center uk-text-middle `}
          >
            <div className="">
              <span
                className={`overview-bold-title uk-width-1-1 ${
                  locationData.hide_since_AT ? 'uk-hidden' : ''
                }`}
              >
                <span placeholder-mif-translation-key="trend">{t("Trend")}</span>
              </span>

              <span
                className={`trend-icon ${
                  locationData.hide_since_AT ? 'uk-hidden' : ''
                } `}
                style={{ '--color': locationData.subject_color }}
              >
                <img src={`/img/icons/trend_${locationData.ch_updown}.svg`} />
              </span>

              <span
                className={`trend-icon-aat ${
                  locationData.hide_since ? 'uk-hidden' : ''
                }`}
              >
                <img
                  src={`/img/icons/trend_aat_${locationData.ch_updown}.svg`}
                />
              </span>

              <span className="overview-bold-title uk-width-1-1 ">
                {locationData.overall_change_aat ? (
                  locationData.overall_change_aat
                    .split(' ')
                    .map((label, index) => (
                      <div className={'overview-bold-title '}>
                        {label}
                        {locationData.overall_change_aat.split(' ').length
                          - 1
                          === index && !locationData.isTakingScreenshot ? (
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              showModal(
                                <ClassificationInfo
                                  title={locationData.overall_change_aat}
                                />,
                              );
                            }}
                            className={`primary-button__classification-info ${styles.infoIcon} uk-icon-information`}
                          >
                            <i
                              className="information_icon"
                              uk-icon="icon:info; ratio:1"
                            ></i>
                          </a>
                          ) : null}
                      </div>
                    ))
                ) : (
                  <span
                    className={'overview_small_score'}
                    placeholder-mif-translation-key="overall_change"
                  >
                    {scoreChangeFormat(locationData.overall_change_at)}
                  </span>
                )}
              </span>
              <h4
                className={` ${
                  locationData.hide_since_AT ? 'uk-hidden' : ''
                } overview-body-copy `}
              >
                <span>
                  {t("Absolute trend since {{year}}", { year: defaultVariables.earliest_year})}
                </span>
              </h4>

              <h4
                className={`${
                  locationData.hide_since ? 'uk-hidden' : ''
                } overview-body-copy `}
              >
                {scoreChangeFormat(locationData.change_0, true)}{' '}
                <span>{t("AAT since {{year}}", { year: defaultVariables.earliest_year})}</span>
                <br />
                {scoreChangeFormat(locationData.change_1, true)}{' '}
                <span>{t("AAT since {{year}}", { year: defaultVariables.mid_year})}</span>
                <br />{' '}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`overview-full overview-white ${locationData.hide_children}`}
        id="footerOverview"
      >
        <div className="uk-container ">
          <div className="overview_subheadline uk-margin-large-bottom">
            {locationData.show_children ? t("{{level_name}} scores", {level_name: locationData.sub_name}) : "" }
          </div>
          <div
            className=" uk-child-width-1-4@m uk-child-width-1-2@s uk-grid-divider justify-content-center"
            uk-height-match=".overview-body-copy, .subject-name"
            uk-grid="{}"
          >
            {locationData.show_children
              ? locationData.children.map((e) => (
                  <OverviewChildSubject
                    key={`inner_overview_subject_${e.s_id}`}
                    location={locationData.iso}
                    subject={e.s_id}
                  />
              ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};
