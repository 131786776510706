import {getAppSelectedLanguageId} from "../index";

const toTitleCase = (str) => str.replace(
  /\w\S*/g,
  (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
);

export default (state, key, retainCase) => {

  const active_language = getAppSelectedLanguageId();

  const obj = state.descriptors.find(
    (n) => n.uid === key && n.lang === active_language,
  );

  let word = obj ? obj.wd : '';
  // word = !retainCase
  //   ? toTitleCase(word)
  //     .replace(' Of The ', ' of the ')
  //     .replace(' And ', ' and ')
  //     .replace('-b', '-B')
  //     .replace('-s', '-S')
  //     .replace('-o', '-O')
  //     .replace(' To ', ' to ')
  //     .replace(' With ', ' with ')
  //     .replace(' By ', ' by ')
  //     .replace(' In The ', ' in the ')
  //     .replace(' Of ', ' of ')
  //     .replace(' In ', ' in ')
  //     .replace(' On ', ' on ')
  //     .replace(' & It ', ' & IT ')
  //     .replace(' (Art) ', ' (ART) ')
  //     .replace("D'iv", "d'Iv")
  //     .replace(' For ', ' for ')
  //     .replace('Dr Congo', 'DR Congo')
  //   : word;

  return word;
};
