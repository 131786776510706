// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".exploreWrapper--3VkYh {\n    position: relative;\n}\n", ""]);
// Exports
exports.locals = {
	"exploreWrapper": "exploreWrapper--3VkYh"
};
module.exports = exports;
