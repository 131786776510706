import React, { useEffect } from "react";
import { useAppContext } from "../../../../context/AppState.jsx";
import { useModalContext } from "../../../../context/modalContext.jsx";
import { Share, Information } from "../../../../components/Modals";
import { urls } from "../../../../referenceData";
import {
	getIdForSharableUrl,
	getUrlQueryParams,
	takeScreenshot,
	setUrlQueryParam,
	removeUrlQueryParam,
} from "../../../../actions";
import {useTranslation} from "react-i18next";
import i18n from "../../../../i18n";

export default () => {
	const [state, dispatch] = useAppContext();
	const [showModal, closeModal] = useModalContext();
	const urlParams = getUrlQueryParams();
	const urlParamsToString = JSON.stringify(urlParams);
	const shareModalState = state.shareModalState || {};
	const shouldResetModalState = shareModalState?.sharableUrl !== urlParamsToString;

	const onClose = (modalState) => {
		dispatch({
			type: "shareModalState",
			payload: modalState,
			onError: i18n.t("There was a problem. Your view could not be saved."),
		});
		closeModal();
	};

	const expandShareUrl = (_url) => {
		let url = new URL(_url);
		if (state.tableFilters) {
			url.searchParams.set('filters', JSON.stringify(state.tableFilters));
		}
		url.searchParams.set('lng', i18n.language);
		return url.toString();

	}

	const onScreenshotTake = () => {
		let url = urls.ssUrl() + "screenshot" + global.location.pathname + global.location.search;
		location.href = expandShareUrl(url);
		const {t } = useTranslation();
		// window.open(
		// 	url,
		// 	'_blank' // <- This is what makes it open in a new window.
		// );
		// dispatch({
		// 	type: "setUrlQueryParam",
		// 	payload: setUrlQueryParam({ name: "takingss", value: true }),
		// 	onError: "There was a problem. Your view could not be saved.",
		// });
		//
		showModal(
			<Information
				title={t("Preparing your screenshot")}
				description={t("Please wait a few moments and it will be downloaded.")}
			/>
		);
		// setTimeout(() => closeModal(), 10000);

		//
		// const onFinish = () => {
		// 	showModal(<Information title="Downloading the file" description="" />);
		//
		// 	dispatch({
		// 		type: "setUrlQueryParam",
		// 		payload: removeUrlQueryParam({ name: "takingss" }),
		// 		onError: "There was a problem. Your view could not be saved.",
		// 	});
		// 	setTimeout(() => closeModal(), 2000);
		// };
		//
		// setTimeout(() => takeScreenshot({ onFinish }), 5000);
	};

	const onPdfTake = () => {
		let url = urls.ssUrl() + "print" + global.location.pathname + global.location.search;
		location.href = expandShareUrl(url);
		const {t } = useTranslation();
		// window.open(
		// 	url,
		// 	'_blank' // <- This is what makes it open in a new window.
		// );
		// dispatch({
		// 	type: "setUrlQueryParam",
		// 	payload: setUrlQueryParam({ name: "takingss", value: true }),
		// 	onError: "There was a problem. Your view could not be saved.",
		// });
		//
		showModal(
			<Information
				title={t("Preparing your PDF")}
				description={t("Please wait a few moments and it will be downloaded.")}
			/>
		);
		// setTimeout(() => closeModal(), 10000);

		//
		// const onFinish = () => {
		// 	showModal(<Information title="Downloading the file" description="" />);
		//
		// 	dispatch({
		// 		type: "setUrlQueryParam",
		// 		payload: removeUrlQueryParam({ name: "takingss" }),
		// 		onError: "There was a problem. Your view could not be saved.",
		// 	});
		// 	setTimeout(() => closeModal(), 2000);
		// };
		//
		// setTimeout(() => takeScreenshot({ onFinish }), 5000);
	};

	useEffect(() => {
		const run = async () => {
			if (shouldResetModalState) {
				shareModalState.sharableId = undefined;
				shareModalState.sharableUrl = urlParamsToString;
				shareModalState.activeTab = "share";
				shareModalState.showEmailInput = false;
				shareModalState.showNotification = false;
				shareModalState.addressEmailToRecipient = "";
				shareModalState.saveToLibraryDialogue = false;
				dispatch({
					type: "shareModalState",
					payload: shareModalState,
					onError: i18n.t("There was a problem. Your view could not be saved."),
				});
				try {
					const { view_id: viewId } = await getIdForSharableUrl({
						saveViewParams: {
							version: 2,
							urldata: urlParamsToString,
						},
					});
					shareModalState.sharableId = viewId;
					shareModalState.sharableUrl = urlParamsToString;
					dispatch({
						type: "shareModalState",
						payload: shareModalState,
						onError: i18n.t("There was a problem. Your view could not be saved."),
					});
					if (viewId) {
						showModal(
							<Share
								sharableId={viewId}
								shouldResetModalState={shouldResetModalState}
								urlParams={urlParams}
								onClose={onClose}
								previouseModalState={state?.shareModalState}
								state={state}
								onScreenshotTake={onScreenshotTake}
								onPdfTake={onPdfTake}
							/>
						);
					}
				} catch (err) {
					console.log(err);
					const { t } = useTranslation();
					showModal(
						<Information title={t("There was a problem.")} description={t("Your view could not be saved.")} />
					);
				}
			}
		};
		run();
	}, [state.setUrlQueryParam]);

	return (
		<Share
			sharableId={state?.shareModalState?.sharableId}
			shouldResetModalState={shouldResetModalState}
			urlParams={urlParams}
			onClose={onClose}
			previouseModalState={state?.shareModalState}
			state={state}
			onScreenshotTake={onScreenshotTake}
			onPdfTake={onPdfTake}
		/>
	);
};
