import React from "react";
import styles from "../../styles.css";
import quickSelectionStyles from "./styles.css";

import { isAllChildrensSelected } from "../../utils";
import {useTranslation} from "react-i18next";

export default ({
	mainSubjectList,
	selectedSubjects,
	removeSelectedSubject,
	addSelectedSubject,
	quickSelectionDescription,
}) => {
	let allMeasures = ["GOVERNANCE"];
	let allCategories = [];
	let allSubCategories = [];
	let allIndicators = [];
	let allSubIndicators = [];

	const mainSubjectListWithoutCitVoices = mainSubjectList.filter((subject) => subject.id !== "CITVOICE");
	const {t} = useTranslation();

	const getDependantChildrens = (list) => {
		list.forEach((child) => {
			if (child.childrens) {
				getDependantChildrens(child.childrens);
			}
			if (child.itemType === "Categories") {
				allCategories.push(child.id);

				allMeasures.push(child.id);
			}
			if (child.itemType === "SubCategories") {
				allSubCategories.push(child.id);
				allMeasures.push(child.id);
			}
			if (child.itemType === "Indicators") {
				allIndicators.push(child.id);
				allMeasures.push(child.id);
			}
			if (child.itemType === "SubIndicators") {
				allSubIndicators.push(child.id);
				allMeasures.push(child.id);
			}
		});
	};

	getDependantChildrens(mainSubjectListWithoutCitVoices);
	return (
		<div className={quickSelectionStyles.measureQuick}>
			<h6>
				{quickSelectionDescription.label}
				<span
					onClick={() => quickSelectionDescription.onClick()}
					uk-icon="info"
					className={`uk-icon-information ${styles.informationIcon}`}
				></span>
			</h6>
			<a
				onClick={(e) => {
					e.preventDefault();
					if (isAllChildrensSelected(allMeasures, selectedSubjects)) {
						removeSelectedSubject({
							subjectsGroup: ["GOVERNANCE", "_1", "_2", "_3", "_4", ...allMeasures],
							selectedSubjects,
						});
					} else {
						addSelectedSubject({
							subjectsGroup: allMeasures,
							selectedSubjects,
						});
					}
				}}
				href="#"
				className={`all_link ${
					isAllChildrensSelected(allMeasures, selectedSubjects) ? quickSelectionStyles.selected : ""
				} ${quickSelectionStyles.measureQuickButton}`}
			>
				{t("All Measures")}
			</a>
			<a
				onClick={(e) => {
					e.preventDefault();
					if (isAllChildrensSelected(allCategories, selectedSubjects)) {
						removeSelectedSubject({
							subjectsGroup: ["_1", ...allCategories],
							selectedSubjects,
						});
					} else {
						addSelectedSubject({
							subjectsGroup: allCategories,
							selectedSubjects,
						});
					}
				}}
				href="#"
				className={`all_link ${
					isAllChildrensSelected(allCategories, selectedSubjects) ? quickSelectionStyles.selected : ""
				} ${quickSelectionStyles.measureQuickButton}`}
			>
				{t("All Categories")}
			</a>
			<a
				onClick={(e) => {
					e.preventDefault();
					if (isAllChildrensSelected(allSubCategories, selectedSubjects)) {
						removeSelectedSubject({
							subjectsGroup: ["_2", ...allSubCategories],
							selectedSubjects,
						});
					} else {
						addSelectedSubject({
							subjectsGroup: allSubCategories,
							selectedSubjects,
						});
					}
				}}
				href="#"
				className={`all_link ${
					isAllChildrensSelected(allSubCategories, selectedSubjects) ? quickSelectionStyles.selected : ""
				} ${quickSelectionStyles.measureQuickButton}`}
			>
				{t("All Sub-Categories")}
			</a>
			<a
				onClick={(e) => {
					e.preventDefault();
					if (isAllChildrensSelected(allIndicators, selectedSubjects)) {
						removeSelectedSubject({
							subjectsGroup: ["_3", ...allIndicators],
							selectedSubjects,
						});
					} else {
						addSelectedSubject({
							subjectsGroup: allIndicators,
							selectedSubjects,
						});
					}
				}}
				href="#"
				className={`all_link ${
					isAllChildrensSelected(allIndicators, selectedSubjects) ? quickSelectionStyles.selected : ""
				} ${quickSelectionStyles.measureQuickButton}`}
			>
				{t("All Indicators")}
			</a>
			<a
				onClick={(e) => {
					e.preventDefault();
					if (isAllChildrensSelected(allSubIndicators, selectedSubjects)) {
						removeSelectedSubject({
							subjectsGroup: ["_4", ...allSubIndicators],
							selectedSubjects,
						});
					} else {
						addSelectedSubject({
							subjectsGroup: allSubIndicators,
							selectedSubjects,
						});
					}
				}}
				href="#"
				className={`all_link ${
					isAllChildrensSelected(allSubIndicators, selectedSubjects) ? quickSelectionStyles.selected : ""
				} ${quickSelectionStyles.measureQuickButton}`}
			>
				{t("All Sub-Indicators")}
			</a>
		</div>
	);
};
