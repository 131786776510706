import React from "react";
import styles from "../../styles.css";
import citVoicePaneStyles from "./styles.css";
import { default as CitVoiceIndicators } from "../CitVoiceIndicators/index.jsx";

import { isAllChildrensSelected } from "../../utils";
import {useTranslation} from "react-i18next";

export default ({
	citvoicesSubjectList,
	corespondingMainSubjectId,
	nestedListsToShow,
	showNestedLists,
	selectedSubjects,
	onSubjectSelect,
	subjectColor,
	subjectType,
	removeSelectedSubject,
	addSelectedSubject,
	queryParams,
}) => {

	const {t} = useTranslation();

	if (subjectType === "") {
		return null;
	}
	const mapCorespondingSubjectIdToCitiVoiceId = {
		SROL: "PubPercROLS",
		PRI: "PubPercPRI",
		FEO: "PubPercEOF",
		HD: "PubPercHD",
	};

	const corespondingCitVoices = citvoicesSubjectList.filter(
		(subject) => subject.id === mapCorespondingSubjectIdToCitiVoiceId[corespondingMainSubjectId]
	);

	let subjectsGroup = [corespondingCitVoices[0].id];

	const getSubjectChildrens = (childrens) => {
		childrens.forEach((child) => {
			if (child.childrens) {
				getSubjectChildrens(child.childrens);
			}
			subjectsGroup.push(child.id);
		});
	};

	getSubjectChildrens(corespondingCitVoices[0].childrens);

	return (
		<div className="uk-width-1-1 app-menu-citvoice-section uk-grid-margin uk-first-column">
			<ul
				className="app-menu-list uk-child-width-1-4@m uk-grid"
				style={{
					border: `2px solid rgb(${subjectColor})`,
				}}
			>
				{corespondingCitVoices.map((citVoiceSubCategory, index) => (
					<React.Fragment key={`${citVoiceSubCategory.fullName.split(" ").join("")}_${index}`}>
						<li className={`level-2 ${index === 0 ? "uk-first-column" : ""}`}>
							<img className="app-menu-citvoice-section-icon" src={`/img/icons/${corespondingCitVoices[0].id}.svg`} alt={citVoiceSubCategory.fullName} />
							<a
								onClick={(e) => {
									e.preventDefault();
									onSubjectSelect(citVoiceSubCategory.id, queryParams);
								}}
								style={
									selectedSubjects.includes(citVoiceSubCategory.id)
										? {
												background: `rgba(${subjectColor}, 0.25)`,
												border: `1px solid rgb(${subjectColor})`,
										  }
										: null
								}
								href="#"
								className={`${citVoicePaneStyles.subjectName} ${
									selectedSubjects.includes(citVoiceSubCategory.id)
										? `${styles.selected} ${citVoicePaneStyles.selectedCategory}`
										: ""
								}`}
							>
								{citVoiceSubCategory.fullName}
							</a>
						</li>
						{citVoiceSubCategory.childrens ? (
							<CitVoiceIndicators
								indicators={citVoiceSubCategory.childrens}
								nestedListsToShow={nestedListsToShow}
								showNestedLists={showNestedLists}
								selectedSubjects={selectedSubjects}
								onSubjectSelect={onSubjectSelect}
								subjectColor={subjectColor}
								queryParams={queryParams}
							/>
						) : null}
					</React.Fragment>
				))}
				<li
					className={`level-3 level-3-select-all uk-grid-margin uk-first-column ${citVoicePaneStyles.level4SelectAll}`}
				>
					<a
						onClick={(e) => {
							e.preventDefault();
							if (isAllChildrensSelected(subjectsGroup, selectedSubjects)) {
								removeSelectedSubject({
									subjectsGroup,
									selectedSubjects,
								});
							} else {
								addSelectedSubject({
									subjectsGroup,
									selectedSubjects,
								});
							}
						}}
						href="#"
						className={`${
							isAllChildrensSelected(subjectsGroup, selectedSubjects) ? styles.selectedAll : ""
						}`}
						style={
							isAllChildrensSelected(subjectsGroup, selectedSubjects)
								? {
										background: `rgba(${subjectColor}, 0.25)`,
										border: `1px solid rgb(${subjectColor})`,
								  }
								: null
						}
					>
						{t("Select All")}
					</a>
				</li>
			</ul>
		</div>
	);
};
