import { defaultVariables, urls } from '../../referenceData';

export default async () => {
  try {
    const level1and2 = await fetch(
      `${urls.apiUrl()}data/?format=json&year1=${defaultVariables.earliest_year}&year2=${defaultVariables.latest_year}&to_level=2`,// eslint-disable-line i18next/no-literal-string
    );

    const level2and4 = await fetch(
      `${urls.apiUrl()}data/?format=json&year1=${defaultVariables.earliest_year}&year2=${defaultVariables.latest_year}&from_level=3&to_level=4`,// eslint-disable-line i18next/no-literal-string
    );

    const { data: level1and2Json } = await level1and2.json();
    const { data: level3and4Json } = await level2and4.json();

    const mergeAllMeasures = [...level1and2Json, ...level3and4Json];

    return mergeAllMeasures;
  } catch (err) {
    console.log(err);
  }
};
