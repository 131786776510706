// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.textStyling0,\n.textStyling1 {\n    text-transform: uppercase;\n}\n\n.textStyling2 {\n    text-transform: capitalize;\n}\n\n.textStyling3,\n.textStyling4 {\n    font-style: italic;\n    position: relative;\n}\n\n[dir=ltr] .textStyling4 {\n    padding-left: 15px;\n}\n\n[dir=rtl] .textStyling4 {\n    padding-right: 15px;\n}\n\n.textStyling4:before {\n    content: \"-\";\n    position: absolute;\n    top: 2px;\n    width: 5px;\n    line-height: 1em;\n}\n\n[dir=ltr] .textStyling4:before {\n    left: 2px;\n}\n\n[dir=rtl] .textStyling4:before {\n    right: 2px;\n}\n", ""]);
// Exports
exports.locals = {
	"textStyling0": "textStyling0",
	"textStyling1": "textStyling1",
	"textStyling2": "textStyling2",
	"textStyling3": "textStyling3",
	"textStyling4": "textStyling4"
};
module.exports = exports;
