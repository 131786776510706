// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "[dir] .container--2pFED {\n    padding: 30px 15px;\n    background-color: white;\n}\n\n[dir] .wrapper--39A-X {\n    padding: 0 30px;\n    margin-top: 15px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "container--2pFED",
	"wrapper": "wrapper--39A-X"
};
module.exports = exports;
