import React from "react";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";

export default ({
	showAdvancedSettings,
	radioButtons,
	activeSubview,
	advancedSettingsButton,
	shoudlHideShareButton,
	callback,
	radioButtonOnChange,
	isEmbed,
	optionOnChange,
	subviewOptions,
	activeOption,
}) => {
	const {t} = useTranslation();
	return (<div className="uk-container">
			<div
				className={`${styles.wrapper} ${isEmbed ? "uk-margin-top uk-margin-bottom" : "uk-width-1-1"}`}>
				<div className="idp-footer__content closed">
					<div className="idp-footer__buttons">
						<div
							className={styles.subviewWrapper}
							onChange={(e) => {
								radioButtonOnChange(e.target.value);
							}}
						>
							{radioButtons.map((radioButton, index) => (
								<label
									key={`${radioButton.text.split(" ").join("")}_${index}`}
									className="idp-footer__button-wrap"
								>
									<input
										type="radio"
										className={` uk-radio ${styles.radioButton}`}
										value={radioButton.queryParam}
										name={`subview`}
										defaultChecked={activeSubview === radioButton.queryParam}
									/>

									{radioButton.text}
								</label>
							))}
						</div>

						<div
							className={styles.optionsWrapper}
							onChange={(e) => {
								optionOnChange(e.target.value);
							}}
						>
							{subviewOptions.map((option, index) => (
								<label
									key={`${option.text.split(" ").join("")}_${index}`}
									className={`idp-footer__button-wrap ${
										!option.subview.includes(activeSubview) ? styles.disabledRadioButton : ""
									}
								${!activeOption && index === 0 ? styles.selected : ""}
								${activeOption === option.queryParam ? styles.selected : ""}`}
								>
									<input
										disabled={!option.subview.includes(activeSubview)}
										type="radio"
										className={` uk-radio ${styles.radioButton} ${styles.subviewRadioButton}`}
										value={option.queryParam}
										name={`option`}
										defaultChecked={activeOption ? activeOption === option.queryParam : index === 0}
									/>

									{option.text}
								</label>
							))}
						</div>

						<div className="idp-footer__share-wrap">
							{!shoudlHideShareButton ? (
								<button className="uk-button uk-button-default" type="button" onClick={() => callback()}>
									<span className={`uk-icon ${styles.shareButtonIcon}`} uk-icon="icon:social"/>

									<span>{t("Save/share")}</span>
								</button>
							) : null}
						</div>

						{!advancedSettingsButton.shouldShow ? (
							<div className="idp-footer__advanced-wrap">
								<button onClick={() => advancedSettingsButton.onClick()}>
									{t("Advanced")}
									{showAdvancedSettings ? (
										<span uk-icon="icon:minus" className={`minus ${styles.expandIcon}`}/>
									) : (
										<span uk-icon="icon:plus" className={`plus ${styles.expandIcon}`}/>
									)}
								</button>
							</div>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};
