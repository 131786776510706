export default ({
  state, subject, firstYear, lastYear,
}) => {
  const measureData = {};
  Object.keys(state.indexedYml).forEach((yr) => {
    measureData[yr] = Object.values(state.indexedYml[yr][subject])
      .filter((e) => e.v >= 0)
      .sort((e1, e2) => e2.v - e1.v);
  });
  measureData[lastYear] = measureData[lastYear].map((el) => {
    el.change = (
      Number(el.v).toFixed(1)
      - Number(state.indexedYml[firstYear][subject][el.iso].v).toFixed(1)
    ).toFixed(1);
    return el;
  });
  return measureData;
};
