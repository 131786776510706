import * as vars from '../../legacyjs/custom/vars';
import i18n from "../i18n";

const data = {};
const active_language = '1';

export var recreateCustomViewsSection = function () {
  const existingViews = savedviews.getAllCustomViews();
  if (Object.keys(existingViews).length) {
    const $mySavedViews = global.$('.my-saved-views');
    const $mySavedEmptyItem = global.$(
      '<div class="col-12 col-lg-6">\n'
                + '            <div class="saved-item my-saved-view-item">\n'
                + '                <a href="#" class="edit"></a>\n'
                + '                <h4></h4>\n'
                + '                <a href="#" class="btn btn-outline-primary">' + i18n.t('View now') + '</a>\n'
                + '            </div>\n'
                + '        </div>',
    );

    global.$.each(existingViews, (name, view) => {
      if (!isNaN(name)) {
        name = `${i18n.t("Custom View")} #${name}`;// eslint-disable-line i18next/no-literal-string
      }
      const $newItem = $mySavedEmptyItem.clone();
      $newItem.find('h4').text(name);
      $newItem.find('a.btn').attr('href', `app.html?v=${view}`);
      $mySavedViews.append($newItem);
    });

    const $customList = global.$('#lstCustomViews');
    const homeView = window.localStorage && window.localStorage.getItem('home');
    if ($customList.length && Object.keys(existingViews).length) {
      $customList.empty();
      $customList.addClass('d-flex');

      global.$.each(existingViews, (id, view) => {
        let name;
        if (!isNaN(id)) {
          name = `${i18n.t("Custom View")} #${id}`;// eslint-disable-line i18next/no-literal-string
        } else {
          name = id;
        }

        let markup = '<div class="uk-width-1-1 uk-width-1-2@s uk-width-1-3@m ">';
        markup += '<div class="saved-item uk-padding-small" >'
        markup += '<div>';
        markup += `<h5><a href="app.html?v=${view}">${name}</a></h5>`;// eslint-disable-line i18next/no-literal-string
        markup += '<div class="d-flex">';
        markup += `<img class="btn-view ${// eslint-disable-line i18next/no-literal-string
          homeView == view ? 'btn-view-active' : ''
        } btn-home" data-uk-tooltip="{animation:'true'}" title="${
          homeView == view
            ? i18n.t('This is your home view. Click here again to have no home view.')
            : i18n.t('Make this your home view')
        }" onclick="savedviews.setHomeView('${
          view
        }', this)" src="../img/icons/saved-home.svg" />`;
        markup += `<img class="btn-view btn-copy" data-uk-tooltip="{animation:'true'}" title="${i18n.t("Copy to clipboard")}" onclick="savedviews.copyViewToClipboard('${ // eslint-disable-line i18next/no-literal-string
          view
        }')" src="../img/icons/saved-copy.svg" />`;
        markup += `<img class="btn-view" data-uk-tooltip="{animation:'true'}" title="${i18n.t("Share")}" onclick="savedviews.shareView('${ // eslint-disable-line i18next/no-literal-string
          id
        }')" src="../img/icons/saved-share.svg" />`;
        markup += `<img class="btn-view btn-rename" data-uk-tooltip="{animation:'true'}" title="${i18n.t("Rename")}" onclick="savedviews.renameView('${// eslint-disable-line i18next/no-literal-string
          id
        }')" src="../img/icons/saved-edit.svg" />`;
        markup += `<img class="btn-view btn-delete" data-uk-tooltip="{animation:'true'}" title="${i18n.t("Remove")}" onclick="savedviews.removeView('${// eslint-disable-line i18next/no-literal-string
          id
        }', true);" src="../img/icons/saved-bin.svg" />`;
        markup += '</div>';
        markup += '</div>';
        markup += '</div>';
        markup += '</div>';

        $customList.append(markup);
      });
      global.$('#spnNoSavedViews').hide();
    }
  } else {
    global.$('#lstCustomViews').removeClass('d-flex');
    global.$('#lstCustomViews').hide();
    global.$('#spnNoSavedViews').show();
  }
};
let renamingViewKey;
export var renameView = function (viewId) {
  renamingViewKey = viewId;

  let name;
  if (!isNaN(viewId)) {
    name = `${i18n.t("Custom View")} #${viewId}`;// eslint-disable-line i18next/no-literal-string
  } else {
    name = viewId;
  }
  const view = getAllCustomViews()[renamingViewKey];

  document.getElementById('txtViewNameNew').value = name;

  // Show modal
  const modal = UIkit.modal('#modalRename');
  modal.center = true;
  modal.show();

  // Put focus on textbox
  document.getElementById('txtViewNameNew').focus();
  global.$('#btnCompleteRenameView').on('click', () => {
    const newKey = document.getElementById('txtViewNameNew').value;
    renameViewInLS(newKey);
    recreateCustomViewsSection();
  });
};

export var removeView = function (viewId, removeHome) {
  const allCustomViews = getAllCustomViews();
  const newViewCollection = {};

  // If set as home, remove home setting
  const currentHome = window.localStorage && window.localStorage.getItem('home');
  if (currentHome == allCustomViews[viewId] && removeHome) {
    window.localStorage.removeItem('home');
  }

  // Build new collection
  let key;
  for (key in allCustomViews) {
    if (key !== viewId) {
      newViewCollection[key] = allCustomViews[key];
    }
  }

  // Ensure no wasted indices if the view ID was a numeric index
  if (!isNaN(viewId)) {
    let nextId = parseInt(viewId) + 1;

    while (nextId in newViewCollection) {
      // Shift value down one key-index
      newViewCollection[nextId - 1] = newViewCollection[nextId];
      nextId += 1;
    }

    // Remove final index item
    delete newViewCollection[nextId - 1];
  }

  // Write new collection to LS
  window.localStorage.setItem('views', JSON.stringify(newViewCollection));

  recreateCustomViewsSection();
};

export var shareView = function (viewId) {
  const newSavedViewId = viewId;
  const modal = UIkit.modal('#modalShareSocial');
  modal.center = true;
  modal.show();

  global.$('#btnTwitter')
    .off('click')
    .on('click', () => {
      const shareText = active_language == 1
        ? 'Explore this and 10 years of governance data with the Ibrahim Index of African Governance'
        : "Explorez 10 ans de données sur la gouvernance avec l'Indice Ibrahim de la gouvernance en Afrique";
      window.open(
        `https://twitter.com/intent/tweet?url=${// eslint-disable-line i18next/no-literal-string
          vars.url_site
        }%3Fv%3D${
          newSavedViewId
        }&hashtags=IIAG&text=${
          shareText}`,
      );
    });
  global.$('#btnFacebook')
    .off('click')
    .on('click', () => {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${vars.url_site}%3Fv%3D${newSavedViewId}`);// eslint-disable-line i18next/no-literal-string
    });
  global.$('#btnLinkedIn')
    .off('click')
    .on('click', () => {
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&title=${i18n.t("Explore this and 10 years of governance data with the Ibrahim Index of African Governance")}&summary=test summary&url=${// eslint-disable-line i18next/no-literal-string
          vars.url_site
        }%3Fv%3D${
          newSavedViewId}`,
      );
    });
};

export var copyViewToClipboard = function (viewId) {
  const url = `${vars.url_site}?v=${viewId}`;// eslint-disable-line i18next/no-literal-string
  copyStringToClipboard(url);

  // Provide confirmation
  global.$('.uk-tooltip-inner').each(function () {
    global.$(this).html(
      '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Copied!&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;',
    );
  });
};

export var setHomeView = function (viewId, button) {
  const currentHomeView = window.localStorage && window.localStorage.getItem('home');

  // Remove bold from home buttons
  global.$('.btn-view-active').each(function () {
    global.$(this).removeClass('btn-view-active');
  });

  // If this is already home view, remove it. Else, add as home view
  currentHomeView == viewId ? window.localStorage && window.localStorage.removeItem('home') : window.localStorage && window.localStorage.setItem('home', viewId);

  // Redraw this view
  recreateCustomViewsSection();
};

export var getAllCustomViews = function () {
  const existingViews = window.localStorage && window.localStorage.getItem('views');
  return existingViews == null ? {} : JSON.parse(existingViews);
};

var copyStringToClipboard = function (string) {
  const $temp = global.$('<input>');
  global.$('body').append($temp);
  $temp.val(string).select();
  document.execCommand('copy');
  $temp.remove();
};

var renameViewInLS = function (newKey) {
  // Get existing back-end ID
  const backendId = getAllCustomViews()[renamingViewKey];

  // Remove from existing collection
  removeView(renamingViewKey, false);

  saveCustomView(newKey, backendId);
};

var saveCustomView = function (key, id) {
  // Save to LocalStorage
  const allViews = getAllCustomViews();
  let newKey = key;

  // If using default key
  if (key.indexOf('Custom view #') != -1 || key.indexOf('Vue personnalisée #') != -1) {
    newKey = key.replace('Custom view #', '').replace('Vue personnalisée #', '');

    // If the remainder is not a number
    if (isNaN(newKey)) {
      // Can't reliably parse number.  Use original key.
      newKey = key;
    }
  }

  allViews[newKey] = id;
  window.localStorage.setItem('views', JSON.stringify(allViews));

  if (global.$('#chkHome').prop('checked')) {
    window.localStorage.setItem('home', id);
  }
};
