import React from "react";
import styles from "../../styles.css";
import citVoiceIndicatorsStyles from "./styles.css";
import { default as CitVoiceSubIndicators } from "../CitVoiceSubIndicators/index.jsx";

export default ({
	indicators,
	nestedListsToShow,
	showNestedLists,
	selectedSubjects,
	onSubjectSelect,
	subjectColor,
	queryParams,
}) =>
	indicators.map((indicator, index) => (
		<li
			key={`${indicator.fullName.split(" ").join("")}_${index}`}
			className={`uk-grid-margin open ${index === 0 ? "uk-first-column" : ""} ${
				citVoiceIndicatorsStyles.listItem
			}`}
		>
			{indicator.childrens ? (
				<span>
					{!nestedListsToShow.includes(indicator.id) ? (
						<span
							className={styles.appMenuLevel3Expand}
							uk-icon="icon:plus; ratio: 0.75"
							onClick={() => {
								showNestedLists((displayedRows) => [...displayedRows, indicator.id]);
							}}
						></span>
					) : (
						<span
							className={styles.appMenuLevel3Expand}
							uk-icon="icon:minus; ratio: 0.75"
							onClick={() => {
								showNestedLists((displayedRows) =>
									displayedRows.filter((item) => item !== indicator.id)
								);
							}}
						></span>
					)}
				</span>
			) : null}

			<a
				onClick={(e) => {
					e.preventDefault();
					onSubjectSelect(indicator.id, queryParams);
				}}
				href="#"
				style={
					selectedSubjects.includes(indicator.id)
						? {
								background: `rgba(${subjectColor}, 0.25)`,
								border: `1px solid rgb(${subjectColor})`,
						  }
						: null
				}
				className={`${styles.subjectName} ${
					selectedSubjects.includes(indicator.id) ? citVoiceIndicatorsStyles.selected : ""
				}`}
			>
				{indicator.fullName}
			</a>
			{indicator.childrens ? (
				<CitVoiceSubIndicators
					subIndicators={indicator.childrens}
					nestedListsToShow={nestedListsToShow}
					indicatorId={indicator.id}
					selectedSubjects={selectedSubjects}
					onSubjectSelect={onSubjectSelect}
					subjectColor={subjectColor}
					queryParams={queryParams}
				/>
			) : null}
		</li>
	));
