import React, { useState } from "react";
import styles from "./styles.css";

export default ({ child }) => {


	return (
		<div className="info-menu">
			<i
				className="close"
				uk-icon="icon: close; ratio: 2"
				uk-toggle="target: .info-menu; animation: uk-animation-fade"
			/>
			<div
				className={`${styles.tabChildrenFrame}`}
				// key={`subMenu_${index}`}
			>
				{child}
			</div>
		</div>
	);
};
