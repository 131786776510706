import { useEffect } from "react";
import { useAppContext } from "../context/AppState.jsx";
import {
	getAppData,
	getAppSelectedLanguage,
	getCarouselContent,
	getIndexedYmlData, getLocationTitle,
	getMeasures, getSelectedLocationName,
	isCitvoiceMeasure,
} from "../actions";
import { removeUrlQueryParam, retrieveCustomView, setUrlQueryParam } from "../actions";
import i18n from "../i18n";

const createMeasureTreeOrder = (state, measures) => {
	const treeOrder = [];
	let allNonCvSubjectsInLevel;
	let allCvSubjectsInLevel;

	for (let i = 0; i < 5; i++) {
		allNonCvSubjectsInLevel = measures.filter(
			(subject) => subject.lvl === i && !isCitvoiceMeasure(state, subject.s_id)
		);

		allNonCvSubjectsInLevel.forEach((subject) => treeOrder.push(subject.s_id));

		allCvSubjectsInLevel = measures.filter(
			(subject) => subject.lvl+1 === i && isCitvoiceMeasure(state, subject.s_id)
		);

		allCvSubjectsInLevel.forEach((subject) => treeOrder.push(subject.s_id));
	}
	return treeOrder;
};

export default ({ urlParams }) => {
	const [state, dispatch] = useAppContext();
	const { v: savedViewId } = urlParams;

	useEffect(() => {
		if (!state.indexedYml) {
			const run = async () => {
				try {
					const { countries, descriptors, groups, measures, strings } = await getAppData();
					const allMeasures = await getMeasures();
					const indexedYml = getIndexedYmlData(allMeasures);

					const translatedText = strings.reduce((acc, string) => {
						return { ...acc, [string.uid]: string };
					}, {});
					// openIndexedDBConnection({ tableName: "countries", tableData: countries });

					dispatch({
						type: "carouselContent",
						payload: await getCarouselContent(),
						onSuccess: i18n.t("Loading Saved View"),
					});
					dispatch({
						type: "descriptors",
						payload: descriptors
					});
					dispatch({
						type: "fullListOfCountryGroups",
						payload: groups
					});
					dispatch({
						type: "fullListOfMeasures",
						payload: measures,
						onSuccess: i18n.t("Loading list of subjects"),
					});
					dispatch({
						type: "translatedText",
						payload: translatedText,
					});
					dispatch({
						type: "allMeasures",
						payload: allMeasures
					});
					dispatch({
						type: "indexedYml",
						payload: indexedYml
					});
					const object = { fullListOfMeasures: measures };
					dispatch({
						type: "measureTreeOrder",
						payload: createMeasureTreeOrder(object, measures)
					});

					const pretendState = { descriptors};
					countries.sort((a,b) => {
						const nameA = getSelectedLocationName(pretendState, a.iso);
						const nameB = getSelectedLocationName(pretendState, b.iso);
						if (nameA < nameB) return 1;
						if (nameA > nameB) return -1;
						return 0;
					});

					dispatch({
						type: "fullListOfCountries",
						payload: countries,
						onSuccess: i18n.t("Loading list of countries"),
					});

					if (typeof savedViewId !== "undefined") {
						const savedView = await retrieveCustomView(savedViewId);
						removeUrlQueryParam({ name: "v" });

						Object.keys(savedView.urlQueryParams).forEach((param) => {
							setUrlQueryParam({ name: param, value: savedView.urlQueryParams[param] });
						});
						dispatch({
							type: "savedView",
							payload: savedView,
							onSuccess: i18n.t("Saved View Loaded"),
						});
					}
				} catch (err) {
					dispatch({ type: "error", result: err });
				}
			};
			run();
		}
	}, []);
};
