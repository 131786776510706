import React from "react";
import { useAppContext } from "../../../../context/AppState.jsx";
import { getUrlQueryParams } from "../../../../actions";
import { setUrlQueryParamForSubview } from "../../../../actions";
import {
	DataTable,
	EmptySelectionPlaceholder,
	ScatterChart,
	MapChart,
	Overview,
	RangeChart,
	TooManySelectionsPlaceholder,
	BarChart,
	AbsoluteTrendBarChart,
	DriversOfChanges,
	ComparisonScatterChart,
	SignificantChangesTable
} from "../";
import { LoadingSpinner } from "../../../../components";
import styles from "./styles.css";

const hasMissingSelection = (urlParams) =>
	typeof urlParams.view === "undefined" ||
	typeof urlParams.loc === "undefined" ||
	urlParams.loc.length === 0 ||
	typeof urlParams.meas === "undefined" ||
	urlParams.meas.length === 0;

const hasTooManySelectedParams = (meas, loc) =>
	meas.split("-").filter((item) => item !== "").length > 1 && loc.split("-").filter((item) => item !== "").length > 1;

export default ({ isTakingScreenshot = false }) => {
	const [state] = useAppContext();
	let urlParams = getUrlQueryParams();
	let { meas, loc, view, subview, option, showAAT } = urlParams;

	if (hasMissingSelection(urlParams)) {
		return <EmptySelectionPlaceholder />;
	}

	if (hasTooManySelectedParams(meas, loc) && view !== "overview") {
		return <TooManySelectionsPlaceholder />;
	}

	if (!state?.indexedYml) {
		return (
			<div className={styles.spinnerWrapper}>
				<LoadingSpinner />
			</div>
		);
	}

	if (!subview) {
		setUrlQueryParamForSubview(view);
		urlParams = getUrlQueryParams();
		subview = urlParams.subview;
	}

	switch (view) {
		case "comparison":
			return (
				<ComparisonScatterChart state={state} urlParams={urlParams} isTakingScreenshot={isTakingScreenshot} />
			);
		case "overview":
			return <Overview state={state} isTakingScreenshot={isTakingScreenshot} />;
		case "graph":
			if (typeof subview == 'undefined' || subview === "score") {
				if (typeof option === "undefined" || option === "line") {
					return <RangeChart state={state} urlParams={urlParams} isTakingScreenshot={isTakingScreenshot} />;
				}
				if (option === "bar") {
					return <BarChart state={state} urlParams={urlParams} isTakingScreenshot={isTakingScreenshot} />;
				}
			}
			if (subview === "absoluteTrend") {
				return (
					<AbsoluteTrendBarChart
						state={state}
						urlParams={urlParams}
						isTakingScreenshot={isTakingScreenshot}
					/>
				);
			}
			if (subview === "annualAvgTrend") {
				return <ScatterChart state={state} urlParams={urlParams} isTakingScreenshot={isTakingScreenshot}/>;
			}
		case "table":
			if (subview === "score" && showAAT == 3) {
				return <DriversOfChanges urlParams={urlParams} isTakingScreenshot={isTakingScreenshot} />;
			}
			if (subview === "changes") {
				return <SignificantChangesTable urlParams={urlParams} isTakingScreenshot={isTakingScreenshot} />;
			}
			return <DataTable urlParams={urlParams} isTakingScreenshot={isTakingScreenshot} />;
		case "map":
			return <MapChart urlParams={urlParams} isTakingScreenshot={isTakingScreenshot} />;
		default:
			break;
	}
};
