// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".tabButton--3hRPy {\n    color: #343c42;\n    text-transform: uppercase;\n    display: block;\n    text-decoration: none;\n}[dir] .tabButton--3hRPy {\n    background-color: #cecece;\n    border: none;\n    border-bottom: 3px solid #cecece;\n    border-radius: 0;\n    padding: 0.5rem 1rem;\n}\n\n.tabButton--3hRPy:hover {\n    text-decoration: none;\n}\n\n[dir] .tabButton--3hRPy:hover {\n    border-bottom: 3px solid #E6512D;\n}\n\n.tabButtonActive--3G8Wm {\n    color: #000;\n    display: block;\n    text-transform: uppercase;\n    text-decoration: none;\n}\n\n[dir] .tabButtonActive--3G8Wm {\n    background-color: #fff;\n    border: none;\n    border-bottom: 3px solid #E6512D;\n    padding: 0.5rem 1rem;\n}\n\n.tabButtonActive--3G8Wm:hover,\n.tabButtonActive--3G8Wm:focus {\n    text-decoration: none;\n}\n\n.tableHeadline--144cY {\n    font-weight: 600;\n}\n\n.filterWrapper--1nrbF {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n[dir] .filterWrapper--1nrbF {\n    padding: 10px;\n}\n\n[dir] .radioButtonLabel--2yiKX {\n    margin-bottom: 0;\n}\n\n[dir=ltr] .radioButtonLabel--2yiKX {\n    margin-left: 15px;\n}\n\n[dir=rtl] .radioButtonLabel--2yiKX {\n    margin-right: 15px;\n}\n\n[dir=ltr] .radioButtonLabel--2yiKX:first-child {\n    margin-left: 0;\n}\n\n[dir=rtl] .radioButtonLabel--2yiKX:first-child {\n    margin-right: 0;\n}\n\n[dir=ltr] .radioButton--2_tX8 {\n    margin-right: 5px;\n}\n\n[dir=rtl] .radioButton--2_tX8 {\n    margin-left: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"tabButton": "tabButton--3hRPy",
	"tabButtonActive": "tabButtonActive--3G8Wm",
	"tableHeadline": "tableHeadline--144cY",
	"filterWrapper": "filterWrapper--1nrbF",
	"radioButtonLabel": "radioButtonLabel--2yiKX",
	"radioButton": "radioButton--2_tX8"
};
module.exports = exports;
