import React from "react";
import { TooManySelectionsPlaceholder } from "../../../../components";
import i18n from "../../../../i18n";

export default () => {
	const title = i18n.t("Invalid Selection");
	const description = [
		i18n.t("The table and graph views allow you to compare one measure across many locations or multiple measures for a single location."),
		i18n.t("You have selected multiple locations and multiple measures"),
		i18n.t("Please change your selection to select a single measure or single location."),
	];

	return <TooManySelectionsPlaceholder title={title} description={description} />;
};
