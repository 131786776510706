import React from "react";
import {Navbar, NavbarDropdownFrame} from "../../components";
import i18n from "../../i18n";
import { default as LocationPickerMenu } from "../LocationPickerMenu/index.jsx";
import { default as SubjectPickerMenu } from "../SubjectPickerMenu/index.jsx";
import { default as GenericSubmenu } from "../GenericSubmenu/index.jsx";

export default () => {

	const staticHtmlNavbar = document.getElementById("static_navbar");
	if (staticHtmlNavbar) {
		staticHtmlNavbar.classList.add("uk-hidden");
	}

	const aboutChildren = [
		{
			navigateTo: "/about.html",
			label: i18n.t("About the IIAG"),
			isActive: false,
			hasChildren: false,
			image: "/img/nav/about.png",
		},
		{
			navigateTo: "/methodology.html",
			label: i18n.t("Methodology"),
			isActive: false,
			hasChildren: false,
			image: "/img/nav/methodology.png"
		},
		{
			navigateTo: "/downloads.html",
			label: i18n.t("Downloads"),
			isActive: false,
			hasChildren: false,
			image: "/img/nav/downloads.png"
		}
	];

	const logo = { src: "/dist/src/img/logo/logo.svg", navigateTo: window.location.origin, alt: "Mo Ibrahim Foundation" };
	const navigationItems = [
		{
			navigateTo: "/data.html",
			label: i18n.t("Explore Data"),
			isActive: false,
			hasChildren: false,
			liClass: ''
		},
		{
			navigateTo: "#",
			label: i18n.t("Countries"),
			hasChildren: <NavbarDropdownFrame child={<LocationPickerMenu isSimplifiedView={true} />} />,
			isActive: false,
			name: "countries",
			liClass: ''
		},
		{
			navigateTo: "#",
			label: i18n.t("Measures"),
			hasChildren: <NavbarDropdownFrame child={<SubjectPickerMenu isSimplifiedView={true} />} />,
			isActive: false,
			name: "measures",
			liClass: ''
		},
		{
			navigateTo: "#",
			label: i18n.t("About the IIAG"),
			isActive: false,
			hasChildren: <NavbarDropdownFrame child={<GenericSubmenu items={aboutChildren} name={"about"} />} />,
			name: "about",
			liClass: ''
		},
		{
			navigateTo: "/saved-items.html",
			label: i18n.t("Saved Items"),
			isActive: false,
			hasChildren: false,
			liClass: ''
		},
		{
			navigateTo: "https://mo.ibrahim.foundation/iiag",
			label: i18n.t("MIF Website"),
			isActive: false,
			hasChildren: false,
			target: "_blank",
			liClass: "nav-item-orange",
		},
	];
	return <Navbar logo={logo} navigationItems={navigationItems} />;
};
