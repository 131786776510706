import getUrlQueryParams from '../getUrlQueryParams';
import setUrlQueryParam from '../setUrlQueryParam';

const availableSubviews = {
  comparison: ['score', 'trend'],
  overview: ['absoluteTrends', 'annualAvgTrend'],
  graph: ['score', 'absoluteTrend', 'annualAvgTrend'],
  bar: ['score', 'trend'],
  table: ['score', 'rank',], //'drivers','changes'],
  map: ['score', 'trend'],
};

export default (selectedView) => {
  const urlQueryParams = getUrlQueryParams();

  if (
    typeof urlQueryParams.subview === 'undefined'
    || !availableSubviews[selectedView].includes(urlQueryParams.subview)
  ) {
    return setUrlQueryParam({
      name: 'subview',
      value: availableSubviews[selectedView][0],
    });
  }
  return null;
};
