// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".subjectName--2LcxC {\n    color: #000 !important;\n    font-family: museo-sans;\n    font-size: 16px;\n    font-weight: 300;\n    font-style: normal;\n}[dir] .subjectName--2LcxC {\n    cursor: pointer;\n    margin-bottom: 5px !important;\n}\n\n[dir] .level4SelectAll--2sA89 {\n    border-top: #dfdfdf 1px solid;\n    padding-top: 10px;\n    margin-top: 10px !important;\n}\n\n.selectedCategory--3r-cg {\n    font-weight: 700;\n}\n\n[dir=ltr] .selectedCategory--3r-cg {\n    padding-right: 25px !important;\n}\n\n[dir=rtl] .selectedCategory--3r-cg {\n    padding-left: 25px !important;\n}\n", ""]);
// Exports
exports.locals = {
	"subjectName": "subjectName--2LcxC",
	"level4SelectAll": "level4SelectAll--2sA89",
	"selectedCategory": "selectedCategory--3r-cg"
};
module.exports = exports;
