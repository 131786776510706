import React from "react";
import styles from "../../styles.css";
import citVoiceSubIndicatorsStyles from "./styles.css";

export default ({
	subIndicators,
	nestedListsToShow,
	indicatorId,
	selectedSubjects,
	onSubjectSelect,
	subjectColor,
	queryParams,
}) => (
	<ul
		className={`${styles.citVoiceSubIndicatorsList} ${!nestedListsToShow.includes(indicatorId) ? "uk-hidden" : ""}`}
	>
		{subIndicators.map((subIndicator, index) => (
			<li key={`${subIndicator.fullName.split(" ").join("")}_${index}`} className="level-4 ">
				<a
					href="#"
					className={`${styles.subjectName} ${citVoiceSubIndicatorsStyles.subIndicatorItem} ${
						selectedSubjects.includes(subIndicator.id) ? citVoiceSubIndicatorsStyles.selected : ""
					}`}
					style={
						selectedSubjects.includes(subIndicator.id)
							? {
									background: `rgba(${subjectColor}, 0.25)`,
									border: `1px solid rgb(${subjectColor})`,
							  }
							: null
					}
					onClick={(e) => {
						e.preventDefault();
						onSubjectSelect(subIndicator.id, queryParams);
					}}
				>
					{subIndicator.fullName}
				</a>
			</li>
		))}
	</ul>
);
