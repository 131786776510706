import React, { useState, memo } from "react";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";
import {LocationPickerMenu, SubjectPickerMenu, ViewPickerMenu} from "../../reusablePartials";

export default ({ title, dropdownButtons, navigationButton }) => {
	const { t, i18n } = useTranslation();
	return (
		<div className={`explore ${styles.exploreWrapper}`}>
			<div className="uk-container">
				<div className="uk-grid">
					<div className="uk-width-1-1">
						<p className="uk-text-center explore-title">{title}</p>
					</div>
					{dropdownButtons.map((button, index) => (
						<div
							key={`${button.label.split(" ").join("")}_${index}`}
							className={`uk-width-1-1  expand-selection-menu 
							${styles.dropdownButton}
							${!navigationButton ? "uk-width-1-3@s" : "uk-width-1-4@s"}`}
						>
							<button
								onClick={() => button.onClick()}
								className="uk-width-expand menu-open-btn uk-text-left expand-selection-menu"
							>
								<span className="menu-title expand-selection-menu">{button.label}</span>
								<span className="expand-selection-menu" uk-icon="icon: chevron-down" />
							</button>
						</div>
					))}
					{navigationButton ? (
						<div className="uk-width-1-1   uk-width-1-4@m">
							<button className="btn btn-block btn-outline-primary show-comparison">
								{navigationButton.label}
							</button>
						</div>
					) : null}


				</div>

				<SubjectPickerMenu />
				<LocationPickerMenu />
				<ViewPickerMenu />
			</div>

		</div>
	);
};
