import React from "react";
import { EmptySelectionPlaceholder } from "../../../../components";
import i18n from "../../../../i18n";

export default () => {
	const title = [i18n.t("The most comprehensive dataset of African Governance")];
	return (
		<EmptySelectionPlaceholder
			title={title}
			description={i18n.t("Choose a measure, location and view from the options above to get started.")}
		/>
	);
};
