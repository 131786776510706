import React, { useState, memo } from 'react';
import Chart from 'chart.js/auto';
import { prepareChartContainer } from '../../actions';
import { default as LoadingSpinner } from '../LoadingSpinner/index.jsx';
import styles from './styles.css';

const ChartJs = memo(
  ({
    chartTitle,
    chartConfig,
    chartId = 'bar-chart',
    chartSize,
    isTakingScreenshot,
  }) => {
    const [isLoaderShown, showLoader] = useState(true);

    return (
      <div
        className={`relative-parent uk-panel uk-panel-box-secondary uk-text-center uk-text-middle with-transitions uk-container ${styles.chartWrapper}`}
      >
        {chartTitle && !isTakingScreenshot ? <h1 className="graph-title">{chartTitle}</h1> : null}
        <div
          className={`uk-width-1-1 ${!chartSize ? styles.canvasWrapper : ''}`}
        >
          <canvas dir="ltr"
            id={chartId}
            ref={() => {
              const chartStatus = Chart.getChart(chartId);
              if (typeof chartStatus !== 'undefined') {
                chartStatus.destroy();
              }
              new Chart(prepareChartContainer(chartId, chartSize), chartConfig);
              showLoader(false);
            }}
          ></canvas>
          {isLoaderShown ? <LoadingSpinner /> : null}
        </div>
      </div>
    );
  },
);

export default ChartJs;
