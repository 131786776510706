export default ({ searchedPhrase, searchButtonId, menuId }) => {
  if (searchedPhrase.length > 0) {
    global.$(`#${searchButtonId}`).attr('uk-icon', 'icon: close');
  } else {
    global.$(`#${searchButtonId}`).attr('uk-icon', 'icon: search');
  }

  global.$('a.highlight-word').removeClass('highlight-word');

  global.$(`#${menuId}`).find('.measure-group').removeClass('expanded');
  global.$(`#${menuId}`).find('.level-3').removeClass('open');

  if (searchedPhrase.length > 2) {
    const $matches = global.$(`#${menuId}`).find(`a.subject-name:icontains(${searchedPhrase})`);// eslint-disable-line i18next/no-literal-string
    if ($matches.length) {
      $matches.addClass('highlight-word');

      $matches.each((i, highlightedElement) => {
        const $highlightedElement = global.$(highlightedElement);
        if ($highlightedElement.parent().hasClass('level-4')) {
          $highlightedElement.closest('.level-3').addClass('open');
        }
        $highlightedElement.closest('.measure-group').addClass('expanded');
      });
    }
  }
};
