import * as XLSX from 'xlsx';
import downloadSpreadsheet from '../downloadSpreadsheet';
import { getSubjectTitle, getLocationTitle } from '..';
import { sortTableDescending} from '../../components/DataTable/utils';
import i18n from "../../i18n";

const createHyperlink = (fileFormat, linkToSource) => {
  if (fileFormat === 'csv') {
    return `=HYPERLINK("${linkToSource}", "iiag.online")`;// eslint-disable-line i18next/no-literal-string
  }
  return 'iiag.online';
};

const normalizeLetters = (text) => text
  .trim()
  .normalize('NFD')
  .replace(/\p{Diacritic}/gu, '');

export default (linkToSource, fileFormat, state, urlParams) => {
  const title = i18n.t("{{subject}} for {{location}}", {
    subject: getSubjectTitle(state),
    location: getLocationTitle(state)
  });

  const { subview, showAAT } = urlParams;
  // const workbook = XLSX.utils.book_new();
  const data = [[title, createHyperlink(fileFormat, linkToSource)], []];

  let annualFields = state.tableHeader.annualFields;
  let annualFieldSkipIndexes = [];
  if (subview === 'score') {
    let headerKeys = Object.keys(annualFields);
    if (JSON.parse(showAAT)===1) {
      annualFieldSkipIndexes.push(headerKeys.indexOf('AT_Range_1'));
      delete annualFields.AT_Range_1;
    } else if (JSON.parse(showAAT)===0) {
      annualFieldSkipIndexes.push(headerKeys.indexOf('AAT_Range_1_Rounded'));
      annualFieldSkipIndexes.push(headerKeys.indexOf('AAT_Range_2_Rounded'));
      annualFieldSkipIndexes.push(headerKeys.indexOf('classification'));
      delete annualFields.AAT_Range_1_Rounded;
      delete annualFields.AAT_Range_2_Rounded;
      delete annualFields.classification;
    } else if (JSON.parse(showAAT) === 2) {
      delete annualFields.AAT_Range_1_Rounded;
      delete annualFields.AAT_Range_2_Rounded;
      delete annualFields.classification;
      delete annualFields.AT_Range_1;
    }
  }

  const header = [
    state.tableHeader.firstColumn,
    ...state.tableHeader.records,
    ...Object.values(annualFields).map(
      (annualField) => typeof annualField == 'string' ? annualField : annualField.join(' '),
    ),
  ];


  let tableRows = state.dataTable;

  tableRows = sortTableDescending({
    columnId: state.tableHeader.firstColumn,
    tableRows,
    columnType: "firstColumn",
    sortDirection: "des",
  });
  tableRows = tableRows.map((row) => [
    normalizeLetters(row.firstColumn.value),
    ...row.records.map((record) => isNaN(record.value) ? i18n.t('n/a') : Number.parseFloat(record.value).toFixed(1).toString()),
    ...row.annualFields.filter((val,index) => annualFieldSkipIndexes.indexOf(index) === -1).map((annualField) => annualField.value),
  ]);

  data.push(header);
  data.push(...tableRows);

  const ws = XLSX.utils.aoa_to_sheet(data);
  ws.B1.l = { Target: linkToSource, Tooltip: 'Find us @ SheetJS.com!' };

  if (fileFormat === 'csv') {
    const csv = XLSX.utils.sheet_to_csv(ws);
    downloadSpreadsheet(csv, 'iiag.online.csv', 'text/csv;encoding:utf-8');
  }
  // else {
  //   XLSX.utils.book_append_sheet(workbook, ws, "iiag.online");
  //   XLSX.writeFile(workbook, "iiag.online.xlsx");
  // }
};
