// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".locationName--3bLUF {\n    color: rgb(80, 80, 80);\n}\n\n.subjectName--3A6E6 {\n    font-weight: 700;\n}\n\n[dir=ltr] .titleWrapper--1pgy0 {\n    padding-right: 40px;\n}\n\n[dir=rtl] .titleWrapper--1pgy0 {\n    padding-left: 40px;\n}\n\n[dir=ltr] .summaryWrapper--33FCj {\n    border-left: 1px solid #d8d8d8;\n}\n\n[dir=rtl] .summaryWrapper--33FCj {\n    border-right: 1px solid #d8d8d8;\n}\n\n[dir=ltr] .summaryIcon--pHYGf {\n    margin-right: 20px;\n}\n\n[dir=rtl] .summaryIcon--pHYGf {\n    margin-left: 20px;\n}\n\n.positiveChange--1WtRy {\n    color: #657A2A;\n}\n.negativeChange--rSL0j {\n    color: #DF1B12;\n}\n", ""]);
// Exports
exports.locals = {
	"locationName": "locationName--3bLUF",
	"subjectName": "subjectName--3A6E6",
	"titleWrapper": "titleWrapper--1pgy0",
	"summaryWrapper": "summaryWrapper--33FCj",
	"summaryIcon": "summaryIcon--pHYGf",
	"positiveChange": "positiveChange--1WtRy",
	"negativeChange": "negativeChange--rSL0j"
};
module.exports = exports;
