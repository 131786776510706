import React from "react";

export default ({ title, description }) => (
	<div className="uk-padding hero-explore">
		<h1>
			<div>
				{title}
			</div>
		</h1>
		<p>{description}</p>
	</div>
);
