import React from "react";
import styles from "../../styles.css";

export default ({ buttons }) => (
	<div className="app-menu-showclear measure-showclear">
		{buttons.map((button, index) => (
			<a
				key={`${button.label.split(" ").join("")}_${index}`}
				onClick={(e) => {
					e.preventDefault();
					button.onClick();
					if (button.class === "clear-comparison") {
					}
				}}
				href="#"
				className={`uk-button uk-button-default ${button.class} ${styles.menuControlButton}`}
			>
				{button.label}
			</a>
		))}
	</div>
);
