import React, { useRef } from "react";
import IonRangeSlider from "../IonRangeSlider";
import styles from "./styles.css";

export default ({ firstSliderSettings, secondSliderSettings, children, resetAdvancedSettings }) => {
	const firstSliderRef = useRef(firstSliderSettings);
	const secondSliderRef = useRef(secondSliderSettings ? secondSliderSettings : {});

	return (
		<div className={`uk-container ${styles.wrapper}`}>
			<div className="uk-width-1-1">
				{firstSliderSettings ? (
					<div id="sliderContainer" className="idp-footer__date-picker advanced-settings">
						<label>{firstSliderSettings.title}</label>
						<div
							className={`idp-footer__range ${!secondSliderSettings ? "idp-footer__range-full-width" : ""}`}
							id="range_1_wrapper"
						>
							<input
								ref={() => {
									if (JSON.stringify(firstSliderSettings) !== JSON.stringify(firstSliderRef.current)) {
										const slider = global.$(`#${firstSliderSettings.id}`).data("ionRangeSlider");
										slider.update(firstSliderSettings);

										firstSliderRef.current = firstSliderSettings;
									}
									IonRangeSlider(firstSliderSettings.id, firstSliderSettings);
								}}
								type="text"
								id={firstSliderSettings.id}
							/>
						</div>
						{secondSliderSettings ? (
							<div className={`${styles.range2Wrapper}`} id="range_2_wrapper">
								<input
									ref={() => {
										IonRangeSlider(secondSliderSettings.id, secondSliderSettings);
										if (
											JSON.stringify(secondSliderSettings) !== JSON.stringify(secondSliderRef.current)
										) {
											const slider = global.$(`#${secondSliderSettings.id}`).data("ionRangeSlider");
											slider.update(secondSliderSettings);

											secondSliderRef.current = secondSliderSettings;
										}
									}}
									type="text"
									id={secondSliderSettings.id}
								/>
							</div>
						) : null}
					</div>
				) : null}
				{children}
				<div className={`idp-footer__highlight advanced-settings ${styles.resetButtonWrapper}`}>
					<button
						className={`uk-button uk-button-default ${styles.resetButton}`}
						type="button"
						onClick={(e) => {
							e.preventDefault();
							resetAdvancedSettings.onClick();
						}}
					>
						{resetAdvancedSettings.text}
					</button>
				</div>
			</div>
		</div>
	);
};
