import React, { useState, useMemo } from 'react';
import styles from './styles.css';
import { createSpreadsheet } from '../../../actions';
import {useTranslation} from "react-i18next";
import i18n from '../../../i18n';
const getAllCustomViews = () => {
  const existingViews = window.localStorage.getItem('views');
  return existingViews == null ? {} : JSON.parse(existingViews);
};

const saveCustomView = (saveViewState, viewId) => {
  // Save to LocalStorage
  const allViews = getAllCustomViews();
  const newKey = saveViewState.text;

  if (!Object.keys(allViews).includes(newKey)) {
    allViews[newKey] = viewId;
    window.localStorage.setItem('views', JSON.stringify(allViews));
  } else {
    return i18n.t('Saved view with this name already exists');
  }

  if (saveViewState.checkbox) {
    window.localStorage.setItem('home', viewId);
  }
  return i18n.t('Saved view to library');
};

/* eslint-disable i18next/no-literal-string */
const getSharableEmbedView = (embedTabState) => (embedTabState.showInIframe
  ? `<iframe
    id="${embedTabState.sharableContainerId}"
    title="${embedTabState.sharableContainerTitle}"
    width="${embedTabState.sharableContainerWidth}"
    height="${embedTabState.sharableContainerHeight}"
    src="${embedTabState.sharableUrl}${
    embedTabState.showCookieConsent ? '&cookie_accept=0' : ''
  }">
</iframe>
`.trim()
  : `${embedTabState.sharableUrl}${
    embedTabState.showCookieConsent ? '&cookie_accept=0' : ''
  }`);

function copyTable() {
  const el = document.getElementById('mifDataTable');
  var body = document.body, range, sel;

  range = document.createRange();
  sel = window.getSelection();
  sel.removeAllRanges();
  try {
    range.selectNodeContents(el);
    sel.addRange(range);
  } catch (e) {
    range.selectNode(el);
    sel.addRange(range);
  }
  document.execCommand("Copy");

}

/* eslint-enable i18next/no-literal-string */
export default ({
  sharableId,
  urlParams,
  onClose,
  previouseModalState,
  state,
  onScreenshotTake,
  onPdfTake,
}) => {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(
    previouseModalState || {
      activeTab: 'share',
      showEmailInput: false,
      showNotification: false,
      addressEmailToRecipient: '',
      saveToLibraryDialogue: false,
    },
  );

  const [embedTabState, setEmbedTabState] = useState({
    expandAdvanced: false,
    sharableContainerId: 'iiag',
    sharableContainerTitle: t('Ibrahim Index of African Governance (IIAG)'),
    sharableContainerWidth: '100%',
    sharableContainerHeight: 'auto',
    showInIframe: true,
    showCookieConsent: false,
    sharableUrl: window.location.href.replace('data.html', 'embed.html'),
  });

  const [saveViewState, setSaveViewState] = useState({
    checkbox: false,
    text: '',
  });

  const shareText = t('Explore this and 10 years of governance data with the Ibrahim Index of African Governance');

  const subject = t('Data on African Governance via iiag.online');

  const { view: activeView, subview } = urlParams;

  const sharableUrl = useMemo(
    () => (sharableId
      ? `${window.location.origin}/data.html?v=${sharableId}`
      : t('Generating view ID')),
    [sharableId],
  );

  const body = t('I thought you might be interested in this data from the Mo Ibrahim Foundation: {{sharableUrl}}', { sharableUrl: sharableUrl });

  return (
    <div className={`${styles.modalShare} uk-modal uk-open`}>
      <div
        className={`${styles.shareModalBody} uk-modal-dialog uk-modal-dialog-share"`}
      >
        <button
          className={`uk-modal-close-default uk-close-large ${styles.closeButton}`}
          uk-icon="icon:close"
          type="button"
          onClick={() => onClose(modalState)}
        />
        <div className={styles.tabsWrapper}>
          <label
            className={`${styles.tabButton} ${
              modalState.activeTab === 'share' ? styles.tabButtonActive : ''
            }`}
            onClick={() => {
              setModalState({
                ...modalState,
                activeTab: 'share',
                showNotification: false,
              });
            }}
          >
            <span uk-icon="icon: social" />
            {t('Share')}
          </label>
          <label
            className={`${styles.tabButton} ${
              modalState.activeTab === 'embed' ? styles.tabButtonActive : ''
            }`}
            onClick={() => {
              setModalState({
                ...modalState,
                activeTab: 'embed',
                showNotification: false,
              });
            }}
          >
            <span uk-icon="icon: code" />
            {t("Embed")}
          </label>
          {modalState.activeTab === 'share' ? (
            <div className={styles.panel}>
              <input
                type="text"
                className={styles.textArea}
                value={sharableUrl}
                readOnly
                onFocus={(event) => event.target.select()}
                onClick={(event) => event.target.select()}
              />
              <a
                onClick={(e) => {
                  e.preventDefault();
                  e.target.closest('a').previousSibling.select();
                  navigator.clipboard.writeText(sharableUrl);
                  setModalState({
                    ...modalState,
                    showNotification: 'Copied to clipboard',
                  });
                }}
                href="#"
                className={styles.copyToClipboardButton}
              >
                <span uk-icon="icon: copy" />
              </a>
            </div>
          ) : (
            <div className={styles.panel}>
              <textarea
                className={`${styles.textArea} ${styles.embedInput}`}
                readOnly
                value={getSharableEmbedView(embedTabState)}
                onFocus={(event) => event.target.select()}
                onClick={(event) => event.target.select()}
              />
              <a
                href="#"
                className={styles.copyToClipboardButton}
                onClick={(e) => {
                  e.preventDefault();
                  e.target.closest('a').previousSibling.select();
                  navigator.clipboard.writeText(
                    getSharableEmbedView(embedTabState),
                  );
                  setModalState({
                    ...modalState,
                    showNotification: 'Copied to clipboard',
                  });
                }}
              >
                <span uk-icon="icon: copy" />
              </a>
              <div className={styles.advancedWrapper}>
                <div
                  onClick={() => setEmbedTabState({
                    ...embedTabState,
                    expandAdvanced: !embedTabState.expandAdvanced,
                  })
                  }
                  className={styles.advancedHeader}
                >
                  {t('Advanced options')}
                  {embedTabState.expandAdvanced ? (
                    <span uk-icon="icon: minus" />
                  ) : (
                    <span uk-icon="icon: plus" />
                  )}
                </div>
              </div>
              {embedTabState.expandAdvanced ? (
                <div className={styles.advancedBody}>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        defaultChecked={embedTabState.showCookieConsent}
                        className={styles.checkbox}
                        onChange={(e) => {
                          setEmbedTabState({
                            ...embedTabState,
                            showCookieConsent: e.target.checked,
                          });
                        }}
                      />
                      {t("Show cookie notice")}
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        className={styles.checkbox}
                        defaultChecked={embedTabState.showInIframe}
                        onChange={(e) => {
                          setEmbedTabState({
                            ...embedTabState,
                            showInIframe: e.target.checked,
                          });
                        }}
                      />
                      {t("Display in iFrame")}
                    </label>
                  </div>
                  {embedTabState.showInIframe ? (
                    <div className={styles.gridContainer}>
                      <div>
                        <label>{t("Size")}:</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          className={styles.advancedInput}
                          placeholder={t("Width")}
                          defaultValue={embedTabState.sharableContainerWidth}
                          onChange={(e) => {
                            setEmbedTabState({
                              ...embedTabState,
                              sharableContainerWidth: e.target.value,
                            });
                          }}
                        />
                        <input
                          type="text"
                          className={styles.advancedInput}
                          placeholder={t("Height")}
                          defaultValue={embedTabState.sharableContainerHeight}
                          onChange={(e) => {
                            setEmbedTabState({
                              ...embedTabState,
                              sharableContainerHeight: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          )}
        </div>

        <div className={styles.socialButtonsWrapper}>
          <p className={styles.socialButtonsTitle}>{t("Share via")}:</p>
          <span
            className={styles.socialButton}
            title={ t("Share to {{service}}", {service:"Twitter"}) }
            onClick={() => window.open(
              `https://twitter.com/intent/tweet?url=${encodeURIComponent(sharableUrl)}&hashtags=IIAG&text=${encodeURIComponent(shareText)}`,
            )
            }
          >
            <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.0340664 0L5.4393 7.17024L0 13H1.22427L5.98647 7.89585L9.83407 13H14L8.29052 5.42651L13.3534 0H12.1292L7.74358 4.70068L4.2 0H0.0340664ZM1.8344 0.894591H3.74821L12.1994 12.1054H10.2856L1.8344 0.894591Z" fill="white"/>
            </svg>
          </span>
          <span
            className={styles.socialButton}
            title={ t("Share to {{service}}", {service:"Facebook"}) }
            uk-icon="icon: facebook"
            onClick={() => window.open(
              `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(sharableUrl)}`,
            )
            }
          ></span>
          <span
            className={styles.socialButton}
            title={ t("Share to {{service}}", {service:"LinkedIn"}) }
            uk-icon="icon: linkedin"
            onClick={() => window.open(
              `https://www.linkedin.com/shareArticle?mini=true&title=Explore this and 10 years of governance data with the Ibrahim Index of African Governance&summary=test summary&url=${encodeURIComponent(sharableUrl)}`,
            )
            }
          ></span>
          <span
            className={styles.socialButton}
            title={ t("Share to {{service}}", {service:"WhatsApp"}) }
            uk-icon="icon: whatsapp"
            onClick={() => window.open(
              `https://wa.me/?text=Explore this and 10 years of governance data with the Ibrahim Index of African Governance ${encodeURIComponent(sharableUrl)}`,
            )
            }
          ></span>
          <span
            className={styles.socialButton}
            title={ t("Share by {{service}}", {service:"Email"}) }
            onClick={() => {
              setModalState({
                ...modalState,
                showEmailInput: true,
              });
            }}
          >
            <img src="/dist/src/img/icons/email_icon.svg" />
          </span>
        </div>
        {modalState.showEmailInput ? (
          <div className="justify-content-center pt-3">
            <div className="pt-2 pb-2">
              <label className={styles.emailTitle}>
                {t("Enter recipient’s email:")}
              </label>
              <input
                type="email"
                className={styles.textArea}
                placeholder="mymail@mail.com"
                onChange={(e) => {
                  setModalState({
                    ...modalState,
                    addressEmailToRecipient: e.target.value,
                  });
                }}
              />
            </div>
            <a
              href={`mailto:${modalState.addressEmailToRecipient}?subject=${subject}&body=${body}`}
              className={`${styles.shareButton} ${styles.confirmButton}`}
            >
              <span className={styles.buttonIcon} uk-icon="icon: forward" />
              {t("Send email")}
            </a>
          </div>
        ) : null}
        <hr className={styles.devider} />
        <div className={`${styles.shareButtonsWrapper} justify-content-center`}>
          <div
            onClick={() => {
              // global.$(".measure-icon-image").each((_, image) => {
              //   insertImageIntoCanvas({ image });
              // });
              // prepareScreenForScreenshot(activeView, subview);
              // html2canvas stopps DOM therefore blocks information modal animation
              // timeout delays html2canvas library in order to display modal
              onScreenshotTake();
            }}
            className={styles.shareButton}
          >
            <span className={styles.buttonIcon}>
              <img src="/dist/src/img/icons/camera_icon.svg" />
            </span>
            {t("Take screenshot")}
          </div>
          <div
            id="btnDownloadPdf"
            className={styles.shareButton}
            onClick={() => {
              onPdfTake();
            }}
          >
            <span>
              <img
                src="/dist/src/img/icons/document_pdf_icon_1x.svg"
                className={styles.buttonIcon}
              />
            </span>
            {t("Download PDF")}
          </div>
          <div
            onClick={() => {
              setModalState({
                ...modalState,
                saveToLibraryDialogue: !modalState.saveToLibraryDialogue,
              });
            }}
            className={styles.shareButton}
          >
            <span className={styles.buttonIcon}>
              <img src="/dist/src/img/icons/library_icon_1x.svg" />
            </span>
            {t("Save to library")}
          </div>
          {activeView === 'table' ? (
            <>
              <div className={styles.shareButton}
              onClick={copyTable}>
                <span className={styles.buttonIcon}>
                  <img src="/dist/src/img/icons/copy_icon_dark_1x.svg" />
                </span>
                {t("Copy data")}
              </div>
              {/* <div id="btnDownloadXlsx" class={styles.shareButton}>
                    <span>
                        <img src="/dist/src/img/icons/document_xls_icon_1x.svg" class="" />
                    </span>
                    Download XLS
                </div> */}
              <div
                onClick={() => {
                  createSpreadsheet(sharableUrl, 'csv', state, urlParams);
                }}
                className={styles.shareButton}
              >
                <span className={styles.buttonIcon}>
                  <img src="/dist/src/img/icons/document_csv_icon_1x.svg" />
                </span>
                {t("Download CSV")}
              </div>
            </>
          ) : null}
        </div>
        {modalState.saveToLibraryDialogue ? (
          <>
            <p className={`${styles.saveViewTextArea}`}>
              <input
                type="text"
                className={styles.textArea}
                placeholder={t("Custom view name")}
                onKeyUp={(e) => {
                  setSaveViewState({
                    ...saveViewState,
                    text: e.target.value,
                  });
                }}
              />
            </p>
            <div
              className={styles.block}
              onClick={() => {
                setSaveViewState({
                  ...saveViewState,
                  checkbox: !saveViewState.checkbox,
                });
              }}
            >
              <input
                type="checkbox"
                defaultChecked={saveViewState.checkbox}
                className={styles.checkbox}
              />
              <span
                className="reduced-size-label clickable_pointer"
                title={t("This view will load as the default when visiting iiag.online")}
              >
                {t("Set as home view")}
              </span>
            </div>
            <div
              onClick={() => {
                const notification = saveCustomView(saveViewState, sharableId);
                if (!notification) {
                  onClose(modalState);
                } else {
                  setModalState({
                    ...modalState,
                    showNotification: notification,
                  });
                }
              }}
              className={`${styles.shareButton} ${styles.confirmButton}`}
            >
              <span uk-icon="icon: bookmark" />
              {t("Confirm save")}
            </div>
          </>
        ) : null}

        <div className={styles.notification_wrapper}>
          {modalState.showNotification ? (
            <span className={styles.spanConfirmation}>
              {modalState.showNotification}
              <i uk-icon="icon: check" />
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};
