import React, { useEffect } from "react";
import { DriversOfChangeChart, TooManySelectionsPlaceholder, LoadingSpinner } from "../../../../components";
import { useAppContext } from "../../../../context/AppState.jsx";
import {
	getSelectedLocationName,
	getUrlQueryParams,
	getSelectedLocations,
	getSelectedYears,
	getSubjectColor,
	setDefaultUrlQueryParamsForSubview,
	getLevelForMeasure,
	isACountry,
	getSelectedSubjects,
	getSelectedSubjectName,
	getLevelName, getItemsWithParent,
} from "../../../../actions";
import { defaultVariables } from "../../../../referenceData";
import styles from "./styles.css";
import i18n from "../../../../i18n";

const getIconName = (value) => {
	if (value > 0) {
		return "up";
	}
	if (value < 0) {
		return "down";
	}
	return "zero";
};


const getHeaderPanelLabelData = (selectedMeasure, selectedYears) => {
	return {
		'direction': selectedMeasure.isUnchanged ? i18n.t('Unchanged').toUpperCase() : selectedMeasure.isImproved ? i18n.t("Improved").toUpperCase() : i18n.t("Decreased").toUpperCase(),
		'change': selectedMeasure.change,
		'score': selectedMeasure.currentScore,
		'start_year': selectedYears[0],
		'end_year': selectedYears[selectedYears.length - 1],
	};
}

const getSortedCategories = (categories, dir) => {
	const sortedCategories = [...categories].sort(
		(a, b) => {
			if (dir) {
				return Number(a.annualFields.find((field) => field.column === "AT_Range_1").value) -
					Number(b.annualFields.find((field) => field.column === "AT_Range_1").value)
			} else {
				return Number(b.annualFields.find((field) => field.column === "AT_Range_1").value) -
					Number(a.annualFields.find((field) => field.column === "AT_Range_1").value);
			}
		}
	);
	return sortedCategories.reverse();
};

export default () => {
	const [state, dispatch] = useAppContext();
	const selectedLocations = getSelectedLocations(state);
	const selectedSubjects = getSelectedSubjects(state);
	let urlParams = getUrlQueryParams();

	if (typeof urlParams.range1from === "undefined" || typeof urlParams.range1to === "undefined") {
		setDefaultUrlQueryParamsForSubview(urlParams);
	}

	const selectedSubjectLevel = getLevelForMeasure(state, selectedSubjects[0]);
	const levelName = getLevelName(selectedSubjectLevel);

	if (selectedLocations.length > 1 || selectedSubjects.length > 1) {
		const title = i18n.t('Invalid Selection');
		const description = [
			i18n.t("The Drivers of Change view allows you to view a single measure for a single location."),
			i18n.t("Please change your selection to a single measure and a single location."),
		];

		return <TooManySelectionsPlaceholder title={title} description={description} />;
	}

	if (selectedSubjectLevel > 3) {
		const title = i18n.t('Invalid Selection');
		const description = [
			i18n.t("The Drivers of Change view allows you work with measures down to 'indicator' level."),
			i18n.t("You have selected a {{level}}, please choose a measure at a higher level.", {level: levelName}),
		];

		return <TooManySelectionsPlaceholder title={title} description={description} />;
	}

	const selectedSubjectChildren = getItemsWithParent(state, selectedSubjects[0]);
	const childLevelName = getLevelName(selectedSubjectLevel + 1);
	if (selectedSubjectChildren.length === 0) {
		const title = i18n.t('Invalid Selection');
		const description = [
			i18n.t("The Drivers of Change view only works with measures that have children."),
			i18n.t("The {{level}} you have chosen contains no {{child_level}}, please choose a different measure.", {level: levelName, child_level: childLevelName}),
		];

		return <TooManySelectionsPlaceholder title={title} description={description} />;
	}

	const selectedLocationName = getSelectedLocationName(state, selectedLocations[0]);
	const isCountry = isACountry(selectedLocations[0]);

	const selectedYearsRange1 = getSelectedYears("range1from", "range1to");
	const selectedYearsRange2 = getSelectedYears("range2from", "range2to");

	const hasDefaultYearsSelected =
		selectedYearsRange1[0] === defaultVariables.default_year_array[0] &&
		selectedYearsRange1[selectedYearsRange1.length - 1] ===
			defaultVariables.default_year_array[defaultVariables.default_year_array.length - 1] &&
		selectedYearsRange2[0] === defaultVariables.default_secondary_year_array[0] &&
		selectedYearsRange2[selectedYearsRange2.length - 1] ===
			defaultVariables.default_secondary_year_array[defaultVariables.default_secondary_year_array.length - 1];

	useEffect(() => {
		// blank the data here to try and minimise the chance of rendering with stale data
		// we'd prefer a loading spinner
		dispatch({
			type: "driversOfChanges",
			payload: [],
		});
		const worker = new Worker("./dataTableWebWorker.js", { type: "module" });

		worker.postMessage({
			selectedLocations,
			selectedSubjects: selectedSubjects,
			selectedYears: selectedYearsRange1,
			selectedYears2: selectedYearsRange2,
			urlParams,
			state,
			hasDefaultYearsSelected,
			defaultVariables,
			type: "createDriversOfChanges",
			activeLanguage: i18n.language,
		});

		worker.onerror = (err) => console.log("table worker error", err);
		worker.onmessage = (e) => {
			if (e.data === "loading") {
				dispatch({
					type: "driversOfChanges",
					payload: [],
				});
			} else {
				dispatch({
					type: "driversOfChanges",
					payload: e.data,
				});
				worker.terminate();
			}
		};
	}, [state.setUrlQueryParam, i18n.language]);

	const driversOfChanges = state?.driversOfChanges || [];

	if (driversOfChanges.length !== 1) {
		return (
			<div className={styles.loadingSpinnerWrapper}>
				<LoadingSpinner />
			</div>
		);
	}

	const selectedMeasure = {
		currentScore: driversOfChanges[0].records.sort((a, b) => a.column - b.column )[driversOfChanges[0].records.length-1].value,
		change: driversOfChanges[0].annualFields.find((record) => record.column === "AT_Range_1").value,
		isImproved: Number(driversOfChanges[0].annualFields.find((record) => record.column === "AT_Range_1").value) > 0,
		isUnchanged: Number(driversOfChanges[0].annualFields.find((record) => record.column === "AT_Range_1").value) === 0,
		icon: getIconName(driversOfChanges[0].annualFields.find((record) => record.column === "AT_Range_1").value),
	};


	let childMeasures = driversOfChanges[0].childrens;
	const measureLevels = [childMeasures];
	for (let i = selectedSubjectLevel + 1; i < 4; i++) {
		childMeasures = childMeasures.flatMap((category) => category.childrens);
		measureLevels.push(childMeasures);
	}

	let driverFilter;
	let opposerFilter;

	if (selectedMeasure.isImproved || selectedMeasure.isUnchanged) {
		driverFilter = (row) => Number(row.annualFields.find((field) => field.column === "AT_Range_1").value) > 0;
		opposerFilter = (row) => Number(row.annualFields.find((field) => field.column === "AT_Range_1").value) <= 0;
	} else {
		driverFilter = (row) => Number(row.annualFields.find((field) => field.column === "AT_Range_1").value) < 0;
		opposerFilter = (row) => Number(row.annualFields.find((field) => field.column === "AT_Range_1").value) >= 0;
	}

	let drivers = false;
	let opposers = false;

	const measureLevelsWithDrivingChanges = [];
	const measureLevelsWithOpposingChanges = [];

	for (let i = 0; i < measureLevels.length; i++) {
		measureLevelsWithDrivingChanges.push(getSortedCategories(
			measureLevels[i].filter(driverFilter),
			selectedMeasure.isImproved || selectedMeasure.isUnchanged
		));
		measureLevelsWithOpposingChanges.push(getSortedCategories(
			measureLevels[i].filter(opposerFilter),
			!(selectedMeasure.isImproved || selectedMeasure.isUnchanged)
		));

	}

	if (measureLevelsWithDrivingChanges[0].length > 0) {
		drivers = {
			title: selectedMeasure.isUnchanged ? i18n.t("Underlying positive trend") : i18n.t("Change driven by"),
			tables: [],
		};
		measureLevelsWithDrivingChanges.map((level, index) => {
			const table = {
				header: [
					getLevelName(selectedSubjectLevel + 1 + index),
					i18n.t('{{year}} score', {year: selectedYearsRange1[selectedYearsRange1.length - 1]}),
					i18n.t('Change since {{year}}', {year: selectedYearsRange1[0]}),
				],
				rows: level.map((category) => ({
					firstColumn: {
						icon: category.firstColumn.props.icon,
						color: getSubjectColor({ state, subject: category.firstColumn.props.abb }),
						label: category.firstColumn.value,
						subjectLevel: category.firstColumn.props.subjectLevel,
					},
					score: category.records.sort((a,b) => a.column - b.column)[category.records.length-1].value,
					change: category.annualFields.find((field) => field.column === "AT_Range_1").value,
				})),
			}
			drivers.tables.push(table);
		});
	}

	if (measureLevelsWithOpposingChanges[0].length > 0) {
		opposers = {
			title: selectedMeasure.isUnchanged ? i18n.t("Underlying negative trend") : i18n.t("Change opposed by"),
			tables: []
		};
		measureLevelsWithOpposingChanges.map((level, index) => {
			const table = {
				header: [
					getLevelName(selectedSubjectLevel + 1 + index),
					i18n.t('{{year}} score', {year: selectedYearsRange1[selectedYearsRange1.length - 1]}),
					i18n.t('Change since {{year}}', {year: selectedYearsRange1[0]}),
				],
				rows: level.map((category) => ({
					firstColumn: {
						icon: category.firstColumn.props.icon,
						color: getSubjectColor({ state, subject: category.firstColumn.props.abb }),
						label: category.firstColumn.value,
						subjectLevel: category.firstColumn.props.subjectLevel,
					},
					score: category.records.sort((a,b) => a.column - b.column)[category.records.length-1].value,
					change: category.annualFields.find((field) => field.column === "AT_Range_1").value,
				})),
			}
			opposers.tables.push(table);
		});
	}

	const title = i18n.t("Drivers of Change - {{location}} - {{subject}}", {
		location: selectedLocationName,
		subject: getSelectedSubjectName(state, selectedSubjects[0]),
	});

	const headerPanel = {
		title: {
			location: `${selectedLocationName} ${isCountry ? `` : `(${i18n.t('Average')})`}`,
			subject: getSelectedSubjectName(state, selectedSubjects[0]),
			subjectLevel: selectedSubjectLevel,
		},
		summary: {
			isUnchanged: selectedMeasure.isUnchanged,
			icon: selectedMeasure.icon,
			label: getHeaderPanelLabelData(selectedMeasure, selectedYearsRange1),
		},
	};

	return (
		<DriversOfChangeChart
			selectedSubjectLevel={selectedSubjectLevel}
			title={title}
			fullpage={true}
			headerPanel={headerPanel}
			drivers={drivers}
			opposers={opposers}
		/>
	);
};
