import React from "react";
import { useModalContext } from "../../../context/modalContext.jsx";
import styles from "./styles.css";
import {useAppContext} from "../../../context/AppState.jsx";
import {useTranslation} from "react-i18next";
import {getAppSelectedLanguage} from "../../../actions";

export default ({ title }) => {
	const [_, closeModal] = useModalContext();
	const [state] = useAppContext();
	const {t} = useTranslation()
	const string_97 = state.translatedText[97][getAppSelectedLanguage()];
	return (
		<div className="uk-modal uk-open" style={{ display: "block", overflow: "hidden" }}>
			<div className={`uk-modal-body uk-modal-dialog uk-modal-dialog-custom-width ${styles.modalWrapper}`}>
				<button
					className="uk-modal-close-default uk-close-large uk-icon uk-close"
					uk-icon="icon:close"
					type="button"
					onClick={() => closeModal()}
				/>
				<h2 className=" no_top_margin" id="lbl_location_info_modal_header" placeholder-mif-string-id={112}>
					{t("About {{classification_popup_title}}", {classification_popup_title: title})}
				</h2>
				<hr />
				<div className="uk-grid uk-width-1-1 classification-info uk-overflow-auto">
					<div className="uk-width-1-1">
						<p template-mif-string-id={97} dangerouslySetInnerHTML={{ __html: string_97 }}>
						</p>
						<table className="trends_left_table" style={{ width: "100%" }} cellPadding={1} cellSpacing={0}>
							<tbody>
								<tr className="trends_table_stripe">
									<td width="31%" style={{ height: "24px" }}>
										<span>{t("Classification")}</span>
									</td>
									<td>
										<span>{t("Characteristic")}</span>
									</td>
								</tr>
								<tr className="trends_table_stripe">
									<td width="31%" style={{ height: "24px" }} />
									<td>
										<span>{t("Improved")}</span>
									</td>
								</tr>
								<tr>
									<td width="31%">
										<div className="trends_progress_bar_info">
											<div
												className="progress-bar trends-increasing-improvement"
												style={{ width: "100%" }}
											>
												<span className=" trends_score" >
													{t("Increasing Improvement")}
												</span>
											</div>
										</div>
									</td>
									<td width="69%">
										<span >
											{t("Progress over the last ten years, with the rate of improvement increasing")}
										</span>
									</td>
								</tr>
								<tr>
									<td width="31%">
										<div className="trends_progress_bar_info">
											<div
												className="progress-bar trends-slowing-improvement"
												style={{ width: "100%" }}
											>
												<span className=" trends_score" >
													{t("Slowing Improvement")}
												</span>
											</div>
										</div>
									</td>
									<td width="69%">
										<span>
											{t("Progress over the last ten years, with the rate of improvement slowing")}
										</span>
									</td>
								</tr>
								<tr>
									<td width="31%">
										<div className="trends_progress_bar_info">
											<div
												className="progress-bar trends-warning-signs"
												style={{ width: "100%" }}
											>
												<span className=" trends_score" >
													{t("Warning Signs")}
												</span>
											</div>
										</div>
									</td>
									<td width="69%">
										<span >
											{t("Progress (or no change) over the last ten years, but showing recent decline *")}
										</span>
									</td>
								</tr>
								<tr className="trends_table_stripe">
									<td width="31%" style={{ height: "24px" }} />
									<td>
										<span>{t("Deteriorated")}</span>
									</td>
								</tr>
								<tr>
									<td width="31%">
										<div className="trends_progress_bar_info">
											<div
												className="progress-bar trends-bouncing-back"
												style={{ width: "100%" }}
											>
												<span className=" trends_score" >
													{t("Bouncing Back")}
												</span>
											</div>
										</div>
									</td>
									<td width="69%">
										<span>
											{t("Decline (or no change) over the last ten years, but showing recent progress *")}
										</span>
									</td>
								</tr>
								<tr>
									<td width="31%">
										<div className=" trends_progress_bar_info">
											<div
												className="progress-bar trends-slowing-deterioration"
												style={{ width: "100%" }}
											>
												<span className=" trends_score" >
													{t("Slowing Deterioration")}
												</span>
											</div>
										</div>
									</td>
									<td width="69%">
										<span >
											{t("Decline over the last ten years, but the rate of decline is slowing")}
										</span>
									</td>
								</tr>
								<tr>
									<td width="31%">
										<div className=" trends_progress_bar_info">
											<div
												className="progress-bar trends-increasing-deterioration"
												style={{ width: "100%" }}
											>
												<span className=" trends_score" >
													{t("Increasing Deterioration")}
												</span>
											</div>
										</div>
									</td>
									<td width="69%">
										<span>
											{t("Decline over the last ten years, with the rate of decline increasing")}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
						<p className="pAsterisk">
							{t("Where trends are static (show no change), the following classifications are applied: Static (over ten years) to decline (over five years): ‘Warning Signs’ | Improve (over ten years) to static\r\n                            (over five years): ‘Warning Signs’ | Decline (over ten years) to static (over five years): ‘Bouncing Back’ | Static (over ten years) to improve (over five years): ‘Bouncing Back’<br />\r\n                            <br />\r\n                            A 'No Change' classification is applied in three instances. 1. When the annual average trend is exactly the same in both periods (if annual average trend appears the same but a classification is given, differences will exist beyond the second decimal place). 2. When a country/group achieves the best possible score (100.0) in 2015 and in 2019. 3. When a country/group achieves the worst possible score (0.0) in 2015 and 2019. In these latter two, no classification can be applied as scores cannot go higher or lower.<br />\r\n                            <br />\r\n                            ‘Not classified’ is applied when a country achieves the best possible score of 100.0 after 2015 and maintains it until 2019, or the worst possible score of 0.0 after 2015 and maintains it until 2019, or country data is not available for 2010, 2015 or 2019. In these instances it is not deemed that the characteristics of the trends match any classifications.")}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
