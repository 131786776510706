import React, { useState, memo, useRef, useEffect } from "react";
import styles from "./styles.css";
import { useModalContext } from "../../context/modalContext.jsx";
import { Information } from "../Modals";
import { default as LoadingSpinner } from "../LoadingSpinner/index.jsx";
import { sortTableDescending, columnType, getRowsToExpand, getVisibleRows } from "./utils";
import infoIcon from "../../../img/icons/infoSymbol.png";
import { removeUrlQueryParam, setUrlQueryParam } from "../../actions";
import {useTranslation} from "react-i18next";

const aatColumns = ["AAT_Range_1_Rounded", "AAT_Range_2_Rounded", "classification"];
const atColumns = ["AT_Range_1"];
const rankColumns = ["rank_change"];

const getFistLevelChildrens = (childrens) => childrens.map((child) => child.firstColumn.props.index);

const getFullListOfChildrens = (childrens) => {
	let array = [];
	const getChildrens = (list) => {
		list.forEach((child) => {
			if (child.childrens) {
				getChildrens(child.childrens);
			}
			array.push(child.firstColumn.props.index);
		});
	};
	getChildrens(childrens);
	return array;
};

const FirstHeaderCell = ({ cell, onHeaderClick, sortByColumn }) => {
	const { t } = useTranslation();
	return (
		<th
			key={`headerCell_row_0`}
			className={`${styles.comparisonTableHeader} clickable_pointer rank ${
				sortByColumn.columnId === cell ? styles.sortBy : ""
			}`}
			onClick={() => onHeaderClick({ columnId: cell, columnType: "firstColumn" })}
		>
			<div
				className={`${
					sortByColumn.columnId === cell && sortByColumn.direction === "des" ? styles.descArrow : ""
				}
			${sortByColumn.columnId === cell && sortByColumn.direction === "asc" ? styles.ascArrow : ""}`}
			>
				{t(cell)}
			</div>
		</th>
	);
};

const RecordHeaderCell = ({ cell, cellIndex, onHeaderClick, sortByColumn }) => (
	<th
		onClick={() => onHeaderClick({ columnId: cell, columnType: "record" })}
		key={`headerCell_row_${cellIndex} `}
		className={`${styles.comparisonTableHeader} ${
			sortByColumn.columnId === cell ? styles.sortBy : ""
		} clickable_pointer rank`}
	>
		<div
			className={`${sortByColumn.columnId === cell && sortByColumn.direction === "des" ? styles.descArrow : ""}
			${sortByColumn.columnId === cell && sortByColumn.direction === "asc" ? styles.ascArrow : ""}`}
		>
			{cell}
		</div>
	</th>
);

const AnnualHeaderCell = ({ cells, startingIndex, filters, onHeaderClick, sortByColumn }) => {
	if (filters.subview === "rank") {
		return rankColumns.map((cellName, index) => {
			return (
				<th
					key={`headerCell_row_${startingIndex}_${index}`}
					className={`${styles.comparisonTableHeader} ${
						sortByColumn.columnId === cellName ? styles.sortBy : ""
					} clickable_pointer rank `}
					onClick={() => onHeaderClick({ columnId: cellName, columnType: "annual" })}
				>
					<div
						className={`${
							sortByColumn.columnId === cellName && sortByColumn.direction === "des"
								? styles.descArrowAnnual
								: ""
						}
			${sortByColumn.columnId === cellName && sortByColumn.direction === "asc" ? styles.ascArrowAnnual : ""}`}
					>
						<div className={styles.annualHeaderTitle}>
							{cells[cellName].map((label, index) => (
								<React.Fragment key={`rankLabel_${startingIndex}_${index}`}>
									{label}
									<br />
								</React.Fragment>
							))}
						</div>
					</div>
				</th>
			);
		});
	}

	if (filters.subview === "score" && filters.showAAT) {
		return aatColumns.map((cellName, index) => {
			if (!cells[cellName]) {
				return null;
			}
			return (
				<th
					key={`headerCell_row_${startingIndex}_${index}`}
					className={`${styles.comparisonTableHeader} ${
						sortByColumn.columnId === cellName ? styles.sortBy : ""
					} clickable_pointer rank`}
					onClick={() => onHeaderClick({ columnId: cellName, columnType: "annual" })}
				>
					<div
						className={`${
							sortByColumn.columnId === cellName && sortByColumn.direction === "des"
								? styles.descArrowAnnual
								: ""
						}
			${sortByColumn.columnId === cellName && sortByColumn.direction === "asc" ? styles.ascArrowAnnual : ""}`}
					>
						<div className={styles.annualHeaderTitle}>
							{cells[cellName].map((label, index) => (
								<React.Fragment key={`rankLabel_${startingIndex}_${index}`}>
									{label}
									<br />
								</React.Fragment>
							))}
						</div>
					</div>
				</th>
			);
		});
	}

	if (filters.subview === "score" && !filters.showAAT) {
		return atColumns.map((cellName, index) => (
			<th
				key={`headerCell_row_${startingIndex}_${index}`}
				className={`${styles.comparisonTableHeader} ${
					sortByColumn.columnId === cellName ? styles.sortBy : ""
				} clickable_pointer rank`}
				onClick={() => onHeaderClick({ columnId: cellName, columnType: "annual" })}
			>
				<div
					className={`${
						sortByColumn.columnId === cellName && sortByColumn.direction === "des"
							? styles.descArrowAnnual
							: ""
					}
			${sortByColumn.columnId === cellName && sortByColumn.direction === "asc" ? styles.ascArrowAnnual : ""}`}
				>
					<div className={styles.annualHeaderTitle} key={index}>
						{cells[cellName].map((label, index) => (
							<React.Fragment key={`rankLabel_${startingIndex}_${index}`}>
								{label}
								<br />
							</React.Fragment>
						))}
					</div>
				</div>
			</th>
		));
	}
};

const HeaderRow = ({ tableHeader, onHeaderClick, filters, sortByColumn }) => (
	<thead>
		<tr className={styles.tableHeaderRow}>
			<FirstHeaderCell cell={tableHeader.firstColumn} onHeaderClick={onHeaderClick} sortByColumn={sortByColumn} />
			{tableHeader.records.map((cell, index) => (
				<RecordHeaderCell
					key={`header_record_cell${index}`}
					cell={cell}
					cellIndex={index + 1}
					onHeaderClick={onHeaderClick}
					sortByColumn={sortByColumn}
				/>
			))}
			<AnnualHeaderCell
				cells={tableHeader.annualFields}
				startingIndex={tableHeader.records.length + 1}
				onHeaderClick={onHeaderClick}
				sortByColumn={sortByColumn}
				filters={filters}
			/>
		</tr>
	</thead>
);

const FirstColumnCell = ({
	record,
	expandRow,
	addRowsToShow,
	expandedRows,
	measuresView,
	showModal,
	collapseRow,
	hideRows,
	childrenRows,
	level,
	onInfoIconClick,
}) => {
	const firstLevelChildrens = childrenRows ? getFistLevelChildrens(childrenRows) : [];
	const fullListOfChildrens = childrenRows ? getFullListOfChildrens(childrenRows) : [];
	const {t} = useTranslation();
	const isLocationTableColumn = record.column === "Location";

	return (
		<td
			key={`title_0_${record.props.index}`}
			className={`${styles.heading} ${record.class ? styles[record.class] : ""}`}
		>
			{[...Array(level - 1).keys()].map((_, i) => (
				<span
					key={`separator_${record.props.index}_${i}`}
					className={`
							${styles.separatorSpan}
							${styles[`separatorSpan${i + 1}`]}
							${styles[`rowMeasureLevel${i + 1}`]}`}
				></span>
			))}
			{!isLocationTableColumn ? (
				<div className={styles.measureIconWrapper}>
					<span
						className={`${styles.measureIcon}`}
						style={
							record.props.icon
								? {
										backgroundImage: `url("/img/icons/${record.props.icon}.svg")`,
								  }
								: null
						}
					></span>
				</div>
			) : null}
			<h4
				className={`${
					isLocationTableColumn
						? styles[`locationTitle${level}`]
						: styles[`textStyling${record.props.subjectLevel}`]
				} ${styles.rowTitle}`}
			>
				{record.value}
			</h4>
			<span className={styles.rowActionsWrapper}>
				{record.props.hasChildren ? (
					!expandedRows.includes(record.props.index) ? (
						<i
							className={`clickable_pointer uk-icon-tiny uk-icon-chevron-down table-expander plus uk-icon-information uk-icon ${styles.expandIcon}`}
							uk-icon="icon:plus"
							onClick={() => {
								expandRow({ rowId: record.props.index });
								addRowsToShow({ rowsToShow: firstLevelChildrens });
							}}
						></i>
					) : (
						<i
							className={`clickable_pointer uk-icon-tiny uk-icon-chevron-down table-expander plus uk-icon-information uk-icon ${styles.expandIcon}`}
							uk-icon="icon:minus"
							onClick={() => {
								fullListOfChildrens.forEach((child) => {
									if (expandedRows.includes(child)) {
										collapseRow({ rowId: child });
									}
								});
								collapseRow({ rowId: record.props.index });
								hideRows({ rowsToHide: fullListOfChildrens });
							}}
						></i>
					)
				) : null}
				{measuresView && !isLocationTableColumn ? (
					<img
						src={infoIcon}
						className={`clickable_pointer ${styles.infoIcon}`}
						onClick={() => {
							const info = onInfoIconClick(record.props.abb);
							const description = info.description;

							const title = t(`About {{subject}}`, { 'subject': record.value });


							let secondDescription;
							let secondTitle;
							if (info.pub && info.pub !== "-") {
								secondTitle = t(`Data source`);
								secondDescription = [t('{{source}}: {{pub}}', {source: info.source, pub: info.pub}),
								t('Years Covered: {{years}}', { years: info.years }),
								t('Source range: {{range}}', {range: info.range}),
								info.coding]
							} else if (info.source) {
								secondTitle = t(`Data source`);
								secondDescription = info.source;
							}

							showModal(<Information title={title} description={[description]} secondTitle={secondTitle} secondDescription={secondDescription}  />);
						}}
					></img>
				) : null}
			</span>
		</td>
	);
};

const RecordCell = ({
	recordIndex,
	rowIndex,
	record,
	highestValue,
	lowestValue,
	filters,
	onCellClick,
	highlightedCells,
}) => {
	return (
	<td
		onClick={() => onCellClick({ rowIndex, columnName: record.column })}
		key={`record_${recordIndex}_${rowIndex}`}
		className={` comparison_table_score score 
			${styles.recordCell}
			${highlightedCells.rowIndex === rowIndex || highlightedCells.columnName === record.column ? styles.highlightedCell : ""}
			`}
	>
		<div className={` ${styles.borderCell}
			${
				record.estimated && record.trimmed && filters.showTrimmedEstimated ? styles.trimmedEstimated : ""
			}
			${
				record.trimmed && !record.estimated && filters.showTrimmed ? styles.trimmed : ""
			}
			${
				record.estimated && !record.trimmed && filters.showEstimated ? styles.estimated : ""
			}
			${ record.rawIsPositive === true ? styles.regular : styles.inverted }
		`}>

			<div
				className={` ${styles.highLowCell} ${
					record.value === highestValue && ((record.rawIsPositive && filters.showHighest) || (!record.rawIsPositive && filters.showLowest)) && record.value !== "n/a"
						? styles.highestValue
						: ""
				} ${
					record.value === lowestValue && ((record.rawIsPositive && filters.showLowest) || (!record.rawIsPositive && filters.showHighest)) && record.value !== "n/a" ? styles.lowestValue : ""
				}					`
			}
			>
				{record.value}
			</div>
		</div>
	</td>
)};

const getCellProps = (cellValue) => {
	if (cellValue === 0) {
		return;
	}
	if (cellValue > 0) {
		return { class: "positiveFieldNumber", prefix: "+" };
	}
	if (cellValue < 0) {
		return { class: "negativeFieldNumber", prefix: "" };
	}
};

const AnnualCell = ({ recordIndex, rowIndex, record, filters, highlightedCells, onCellClick }) => {
	const cellProps = getCellProps(Number(record.value));
	const { t, i18n } = useTranslation();
	if (filters.subview === "rank" && rankColumns.includes(record.column)) {
		return (
			<td
				dir="ltr"
				onClick={() => onCellClick({ rowIndex, columnName: record.column })}
				key={`record_${recordIndex}_${rowIndex}`}
				className={`comparison_table_score score
				${styles.recordCell}
				${cellProps?.class ? styles[cellProps.class] : ""}
				${highlightedCells.rowIndex === rowIndex || highlightedCells.columnName === record.column ? styles.highlightedCell : ""}
				`}
			>
				<div>{`${cellProps?.prefix ? cellProps?.prefix : ""}${record.value}`}</div>
			</td>
		);
	}

	if (filters.subview === "score" && filters.showAAT && aatColumns.includes(record.column)) {
		if (record.column === "classification") {
			return (
				<td
					key={`record_${recordIndex}_${rowIndex}`}
					className={` comparison_table_score score ${record.class ? styles[record.class] : ""}`}
				>
					{cellProps?.prefix ? cellProps?.prefix : ""}
					{t(record.value)}
				</td>
			);
		} else {
			return (
				<td
					dir="ltr"
					onClick={() => onCellClick({ rowIndex, columnName: record.column })}
					key={`record_${recordIndex}_${rowIndex}`}
					className={` comparison_table_score score ${styles.recordCell} ${
						cellProps?.class ? styles[cellProps.class] : ""
					} ${
						highlightedCells.rowIndex === rowIndex || highlightedCells.columnName === record.column
							? styles.highlightedCell
							: ""
					}`}
				>
					{cellProps?.prefix ? cellProps?.prefix : ""}
					{record.value}
				</td>
			);
		}
	}
	if (filters.subview === "score" && !filters.showAAT && atColumns.includes(record.column)) {
		return (
			<td
				dir="ltr"
				onClick={() => onCellClick({ rowIndex, columnName: record.column })}
				key={`record_${recordIndex}_${rowIndex}`}
				className={` comparison_table_score score ${styles.recordCell} ${
					cellProps?.class ? styles[cellProps.class] : ""
				} ${
					highlightedCells.rowIndex === rowIndex || highlightedCells.columnName === record.column
						? styles.highlightedCell
						: ""
				}`}
			>
				{cellProps?.prefix ? cellProps?.prefix : ""}
				{record.value}
			</td>
		);
	}
};

const CreateTableRows = ({
	measuresView,
	tableRows,
	showModal,
	visibleRows,
	addRowsToShow,
	hideRows,
	expandRow,
	expandedRows,
	collapseRow,
	level,
	filters,
	onCellClick,
	highlightedCells,
	onInfoIconClick,
	subview,
}) =>
	tableRows?.map((row, rowIndex) => {
		if (row.records.length === 0) {
			return '';
		}
		const sortedRecords = [...row.records].sort((a, b) => a.value - b.value);
		const areAllRecordsSame = sortedRecords[0].value === sortedRecords[sortedRecords.length - 1].value;
		let highestValue = null;
		let lowestValue = null;
		if (!areAllRecordsSame) {
			highestValue = subview !== "rank" ? sortedRecords[sortedRecords.length - 1].value : sortedRecords[0].value;
			lowestValue = subview !== "rank" ? sortedRecords[0].value : sortedRecords[sortedRecords.length - 1].value;
		}
		let fullTableRowIndex = row.firstColumn.props.index;

		if (row.firstColumn.props.showInContext) {
			//	expandRow({ rowId: row.firstColumn.props.parent });
			// addRowsToShow({ rowsToShow: firstLevelChildrens });
		}

		return (
			<React.Fragment key={`record_table_row${rowIndex}`}>
				<tr
					key={`tableRow_${rowIndex}_${row.firstColumn.props.index}`}
					className={`
						${row.firstColumn.props.subitem && !visibleRows.includes(row.firstColumn.props.index) ? "uk-hidden" : ""}
						${styles[`rowMeasureLevel${level}`]}
						${row.firstColumn.props.subitem ? row.firstColumn.props.parent : ""}
						${styles.row}
						${row.firstColumn.props.showInContext ? styles.boldTableRow : ""}
					`}
				>
					<FirstColumnCell
						record={row.firstColumn}
						expandRow={expandRow}
						addRowsToShow={addRowsToShow}
						expandedRows={expandedRows}
						measuresView={measuresView}
						showModal={showModal}
						collapseRow={collapseRow}
						hideRows={hideRows}
						childrenRows={row.childrens}
						level={level}
						onInfoIconClick={onInfoIconClick}
					/>
					{row.records.map((record, recordIndex) => {
						return filters.visibleRecordColumns.includes(record.column) ? (
							<RecordCell
								key={`tableRow_${rowIndex}_${row.firstColumn.props.index}_${recordIndex}`}
								recordIndex={recordIndex}
								rowIndex={fullTableRowIndex}
								record={record}
								filters={filters}
								highestValue={highestValue}
								lowestValue={lowestValue}
								onCellClick={onCellClick}
								highlightedCells={highlightedCells}
							/>
						) : null;
					})}
					{row.annualFields.map((record, recordIndex) => {
						return (
							<AnnualCell
								key={`tableRow_${rowIndex}_${row.firstColumn.props.index}_${recordIndex}`}
								recordIndex={recordIndex}
								rowIndex={fullTableRowIndex}
								record={record}
								filters={filters}
								highlightedCells={highlightedCells}
								onCellClick={onCellClick}
							/>
						);
					})}
				</tr>
				{row.childrens ? (
					<CreateTableRows
						key={`row_children_${rowIndex}_${row.firstColumn.props.index}`}
						measuresView={measuresView}
						tableRows={row.childrens}
						showModal={showModal}
						visibleRows={visibleRows}
						addRowsToShow={addRowsToShow}
						hideRows={hideRows}
						expandRow={expandRow}
						expandedRows={expandedRows}
						collapseRow={collapseRow}
						level={level + 1}
						filters={filters}
						onCellClick={onCellClick}
						highlightedCells={highlightedCells}
						onInfoIconClick={onInfoIconClick}
						subview={filters.subview}
					/>
				) : null}
			</React.Fragment>
		);
	});

const QuickActionButtons = ({ tableRows, expandRows, expandedRows, addRowsToShow, showRows }) => {
	const { t} = useTranslation();
	return (
		<div className={styles.quickActionsWrapper}>
		<span
			className={styles.quickActionButton}
			onClick={() => {
				const allChildrenRows = [];
				const allParentRows = [];

				tableRows.forEach((row) => {
					if (row.childrens) {
						allParentRows.push(row.firstColumn.props.index);
						allChildrenRows.push(...getFullListOfChildrens(row.childrens));
					}
				});

				expandRows([...expandedRows, ...allParentRows, ...allChildrenRows]);
				addRowsToShow({rowsToShow: allChildrenRows});
			}}
		>
			{t("Expand ALL")}
		</span>
			<span className={styles.quickActionSeparator}>|</span>
			<span
				className={styles.quickActionButton}
				onClick={() => {
					showRows([]);
					expandRows([]);
				}}
			>
			{t("Contract ALL")}
		</span>
		</div>
	);
}

const DataTable = memo(
	({
		measuresView,
		tableRows,
		tableHeader,
		tableTitle,
		filters,
		onInfoIconClick,
		measureTreeOrder,
		onFilterChange,
		isTakingScreenshot,
	}) => {
		if (tableRows.length === 0) {
			return <LoadingSpinner />;
		}

		const [visibleRows, showRows] = useState(() => {
			if (filters.showFullContext) {
				const rowsToShow = getVisibleRows(tableRows);
				return rowsToShow;
			} else {
				return [];
			}
		});
		const [expandedRows, expandRows] = useState(() => {
			if (filters.showFullContext) {
				const rowsToExpand = getRowsToExpand(tableRows);
				return rowsToExpand;
			} else {
				return [];
			}
		});

		const [showModal] = useModalContext();

		const [highlightedCells, highlightCells] = useState({ rowIndex: "", columnName: "" });
		const [sortByColumn, setSortByColumn] = useState({
			columnId: filters.sortBy === null ? tableHeader.firstColumn : filters.sortBy,
			direction: filters.sortDir === null ? "des" : filters.sortDir,
		});

		useEffect(() => {
			if (filters?.collapseAll) {
				showRows([]);
				expandRows([]);
				removeUrlQueryParam({ name: "collapseAll" });
			}
			if (filters?.showHighlights === false) {
				highlightCells({ rowIndex: "", columnName: "" });
			}
			if (filters?.sortBy && filters?.sortDir) {
				setSortByColumn({ columnId: filters.sortBy, direction: filters.sortDir });
			}
		}, [filters]);


		const [sortedTableRows, sortTableRows] = useState(() =>
			sortTableDescending({
				columnId: sortByColumn.columnId,
				tableRows,
				columnType: columnType(sortByColumn.columnId),
				sortDirection: sortByColumn.direction,
				measureTreeOrder,
			})
		);

		const rowsRef = useRef(tableRows);

		if (JSON.stringify(tableRows) !== JSON.stringify(rowsRef.current)) {
			setTimeout(() => {
				tableRows = sortTableDescending({
					columnId: sortByColumn.columnId,
					tableRows,
					columnType: columnType(sortByColumn.columnId),
					sortDirection: sortByColumn.direction,
					measureTreeOrder,
				});
				setSortByColumn({ columnId: sortByColumn.columnId, direction: sortByColumn.direction });
				sortTableRows(tableRows);
			}, 0);
			rowsRef.current = tableRows;
		}

		const addRowsToShow = ({ rowsToShow }) => {
			showRows([...visibleRows, ...rowsToShow]);
		};

		const hideRows = ({ rowsToHide }) => {
			showRows((rows) => rows.filter((row) => !rowsToHide.includes(row)));
		};

		const collapseRow = ({ rowId }) => {
			expandRows((rows) => rows.filter((row) => row !== rowId));
		};

		const expandRow = ({ rowId }) => {
			expandRows([...expandedRows, rowId]);
		};

		const onHeaderClick = ({ columnId, columnType }) => {
			if (
				sortByColumn.columnId === columnId &&
				sortByColumn.direction === "asc" &&
				columnType !== "firstColumn"
			) {
				let sortParams = { columnId: tableHeader.firstColumn, direction: "des" };
				setSortByColumn(sortParams);
				onFilterChange(sortParams);
				setUrlQueryParam({ name: "sortBy", value: tableHeader.firstColumn });
				setUrlQueryParam({ name: "sortDir", value: "des" });
				sortTableRows(
					sortTableDescending({
						columnId: tableHeader.firstColumn,
						tableRows,
						columnType: "firstColumn",
						sortDirection: "des",
						measureTreeOrder,
					})
				);
			} else if (sortByColumn.columnId === columnId && sortByColumn.direction === "des") {
				let sortParams = { columnId, direction: "asc" };
				setSortByColumn(sortParams);
				onFilterChange(sortParams);
				setUrlQueryParam({ name: "sortBy", value: columnId });
				setUrlQueryParam({ name: "sortDir", value: "asc" });
				sortTableRows(
					sortTableDescending({
						columnId,
						tableRows,
						columnType,
						sortDirection: "asc",
						measureTreeOrder,
						subview: filters.subview,
					})
				);
			} else {
				let sortParams = { columnId, direction: "des" };
				setSortByColumn(sortParams);
				onFilterChange(sortParams);
				setUrlQueryParam({ name: "sortBy", value: columnId });
				setUrlQueryParam({ name: "sortDir", value: "des" });
				sortTableRows(
					sortTableDescending({
						columnId,
						tableRows,
						columnType,
						sortDirection: "des",
						measureTreeOrder,
						subview: filters.subview,
					})
				);
			}
		};

		const onCellClick = ({ rowIndex, columnName }) => {
			if (
				(highlightedCells.rowIndex === rowIndex && highlightedCells.columnName === columnName) ||
				!filters.showHighlights
			) {
				highlightCells({ rowIndex: "", columnName: "" });
			} else {
				highlightCells({ rowIndex, columnName });
			}
		};

		if (tableHeader.length === 0 || tableRows.length === 0 || typeof tableRows === "undefined" || !tableHeader) {
			return (
				<div className={styles.loadingSpinnerWrapper}>
					<LoadingSpinner />
				</div>
			);
		}

		return (
			<div
				className={`${styles.divTable} uk-width-1-1 uk-panel uk-panel-box-secondary uk-text-center uk-text-middle uk-container`}
				style={{ height: "auto" }}
			>
				<h1 className={styles.tableMainHeading}>{tableTitle}</h1>
				{
					(isTakingScreenshot || <QuickActionButtons
						tableRows={tableRows}
						expandRows={expandRows}
						expandedRows={expandedRows}
						addRowsToShow={addRowsToShow}
						showRows={showRows}
					/>)
				}
				<div className={styles.table_scroll}>
					<table id="mifDataTable" className={`uk-width-1-1 ${styles.mifComparisonCable} ${styles.tblComparison}`}>
						<HeaderRow
							tableHeader={tableHeader}
							filters={filters}
							onHeaderClick={onHeaderClick}
							sortByColumn={sortByColumn}
						/>
						<tbody>
							<CreateTableRows
								key={`firstTableRow`}
								measuresView={measuresView}
								tableRows={sortedTableRows}
								showModal={showModal}
								visibleRows={visibleRows}
								addRowsToShow={addRowsToShow}
								hideRows={hideRows}
								expandRow={expandRow}
								expandedRows={expandedRows}
								collapseRow={collapseRow}
								level={1}
								filters={filters}
								onCellClick={onCellClick}
								highlightedCells={highlightedCells}
								onInfoIconClick={onInfoIconClick}
								subview={filters.subview}
							/>
						</tbody>
					</table>
				</div>
				{
					(isTakingScreenshot || <div className={styles.quickActionButtonsWrapper}>
						<QuickActionButtons
							tableRows={tableRows}
							expandedRows={expandedRows}
							expandRows={expandRows}
							addRowsToShow={addRowsToShow}
							showRows={showRows}
						/>
					</div>)
				}
			</div>
		);
	}
);

export default DataTable;
