import React, { useMemo } from "react";
import {
	prettifyNumber,
	getSubjectColor,
	getSelectedSubjectName,
	getSelectedLocationName,
	roundToOne,
	getTopLevelParent,
	getLevelForMeasure,
	getStyleLevelForMeasure,
} from "../../../../actions";
import { defaultVariables } from "../../../../referenceData";
import { LoadingSpinner, InfoPagesDataTable } from "../../../../components";
import i18n from "../../../../i18n";

const subjectHasIcon = (mid, state) => {
	var parent = getTopLevelParent(state, mid);
	if (parent.s_id == "CITVOICE") {
		return getLevelForMeasure(state, mid) < 2;
	}
	return getLevelForMeasure(state, mid) < 3;
};

const getCellClass = (value) => {
	if (value === "n/a" || Number(value) === 0) {
		return;
	}
	if (value > 0) {
		return "positive";
	}
	if (value < 0) {
		return "negative";
	}
};

const createRows = (data, state) => {
	return data.map((row) => {
		return {
			props: {
				class: `level-${getStyleLevelForMeasure(state, row.s_id)}`,
				subjectColor: getSubjectColor({ state, subject: row.s_id }),
				icon: `/img/flags/${row.iso.toLowerCase()}.svg`,
			},
			columns: [
				{
					props: {
						hasIcon: true,
						class: "",
						styles: { "--ttt-icon": `url("/img/flags/${row.iso.toLowerCase()}.svg")` }

					},
					value: getSelectedLocationName(state, row.iso),
				},
				{ props: {}, value: prettifyNumber(roundToOne(row.v)) },
				{
					props: { class: getCellClass(roundToOne(row.change).toFixed(1)) },
					value:
						getCellClass(roundToOne(row.change).toFixed(1)) === "positive"
							? `+${roundToOne(row.change).toFixed(1)}`
							: roundToOne(row.change).toFixed(1),
				},
			],
		};
	});
};

export default ({ state }) => {
	if (!state?.measureData) {
		return <LoadingSpinner />;
	}
	const measureData = { ...state.measureData };

	const lastYear = defaultVariables.latest_year;

	const locationSortFn =
		(prop = "v", dir = 1) =>
		(e1, e2) => {
			let e1v = roundToOne(e1[prop]);
			let e2v = roundToOne(e2[prop]);
			if (e2v === e1v) {
				const cnmA = getSelectedLocationName(state, e1.iso);
				const cnmB = getSelectedLocationName(state, e2.iso);
				if (cnmA < cnmB) {
					return -1;
				}
				if (cnmA > cnmB) {
					return 1;
				}
				return 0;
			}
			if (dir === 1) {
				return e2v - e1v;
			}
			return e1v - e2v;
		};

	measureData[lastYear].sort(locationSortFn());
	const topTenHighestData = createRows(measureData[lastYear].slice(0, 10), state);
	// updateTable(tth, topTenHighestData);

	measureData[lastYear].sort(locationSortFn("v", -1));
	const topTenLowestData = createRows(measureData[lastYear].slice(0, 10), state);
	// updateTable(ttl, topTenLowestData);

	// and then we want the nas removed from consideration for the most improved/deteriorated
	measureData[lastYear] = measureData[lastYear].filter((e) => e.change !== "n/a");

	measureData[lastYear].sort(locationSortFn("change", -1));
	measureData[lastYear].sort((e1, e2) => e2.change - e1.change);
	const topImprovementsData = createRows(measureData[lastYear].slice(0, 10), state);
	// updateTable(ti, topImprovementsData);

	measureData[lastYear].sort(locationSortFn("change", -1));
	const topDeteriorationsData = createRows(measureData[lastYear].slice(0, 10), state);
	// updateTable(td, topDeteriorationsData);

	const tableData = useMemo(
		() => [
			{
				name: "score",
				text: i18n.t("Scores"),
				tables: [
					{
						name: "topTenHighestData",
						title: i18n.t("Top 10 Countries"),
						rows: topTenHighestData,
					},
					{
						name: "topTenLowestData",
						title: i18n.t("Bottom 10 Countries"),
						rows: topTenLowestData,
					},
				],
			},
			{
				name: "trend",
				text: i18n.t("Trends"),
				tables: [
					{
						name: "topImprovementsData",
						title: i18n.t("Most improved Countries"),
						rows: topImprovementsData,
					},
					{
						name: "topDeteriorationsData",
						title: i18n.t("Most deteriorated Countries"),
						rows: topDeteriorationsData,
					},
				],
			},
		],
		[state.shouldShowAllMeasures, i18n.language, state.measureData]
	);

	return <InfoPagesDataTable tableData={tableData} type="subject" />;
};
