import React from "react";
import { SavedViewsCarousel } from "../../components";
import { useAppContext } from "../../context/AppState.jsx";
import i18n from "../../i18n";

export default () => {
	const [state, dispatch] = useAppContext();

	const carouselContent = state?.carouselContent || [];

	const slides = carouselContent.map((slide) => ({
		// eslint-disable-next-line i18next/no-literal-string
		title: slide[`${i18n.language}_name`],
		// eslint-disable-next-line i18next/no-literal-string
		description: slide[`${i18n.language}_description`],
		link: slide.uid,
	}));

	return <SavedViewsCarousel slides={slides} />;
};
