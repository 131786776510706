import { urls } from '../../referenceData';

export default async () => {
  try {
    const res = await fetch(
      `${urls.apiUrl()}most/?format=json`,// eslint-disable-line i18next/no-literal-string
    );

    const toJson = await res.json();

    return toJson;
  } catch (err) {
    console.log(err);
  }
};
