import React from "react";
import { useModalContext } from "../../context/modalContext.jsx";
import { ClassificationInfo } from "../Modals";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";
import {defaultVariables} from "../../referenceData";
import {scoreChangeFormat} from "../../actions";

export default ({ trendData }) => {
	const [showModal] = useModalContext();
	const {t} = useTranslation();
	return (
		<div className="single-trend">
			<span
				className="overview-bold-title uk-width-1-1 top_margin_10  bottom_margin_10 single-trend-title"
				template-mif-string-id="24"
			>
				{t("Trend")}
			</span>
			<span className="overview_small_score block uk-margin-medium-right uk-width-1-1 single-trend-icon">
				<span
					className={`trend-icon-small ${trendData.hide_since_AT}`}
					style={{ "--color": trendData.subject_color }}
				>
					<img src={`/img/icons/trend_${trendData.ch_updown}.svg`} />
				</span>

				<span className={`trend-icon-aat-small ${trendData.hide_since}`}>
					<img src={`/img/icons/trend_aat_${trendData.ch_updown}.svg`} />
				</span>
			</span>
			<span className={`overview_small_score  single-trend-change uk-width-1-1 ${trendData.hide_since_AT} `}>
				{scoreChangeFormat(trendData.ch_at)}
			</span>
			{trendData.ch_aat &&
				trendData.ch_aat.split(" ").map((label, index) => (
					<div className={`overview-bold-title  single-trend-change uk-width-1-1 ${trendData.hide_since} `}>
						{label}
						{trendData.ch_aat.split(" ").length - 1 === index ? (
							<span
								onClick={() => {
									showModal(<ClassificationInfo title={trendData.ch_aat} />);
								}}
								className={`primary-button__classification-info-multi-location ${trendData.hide_since} ${trendData.hide_info_icon} ${styles.infoIcon} information_icon uk-icon-information`}
							>
								<i className=" " uk-icon="icon:info; ratio:1"></i>
							</span>
						) : null}
					</div>
				))}

			<span
				className={`overview-small-text uk-width-1-1 top_margin_5  ${trendData.hide_since} single-trend-detail`}
				id={`txtSince_${trendData.index}`}
			>
				{scoreChangeFormat(trendData.change_0, true)} <span template-mif-string-id="114">{t("AAT since {{year}}", {year: defaultVariables.earliest_year})}</span>
				<br />
				{scoreChangeFormat(trendData.change_1, true)} <span template-mif-string-id="115">{t("AAT since {{year}}", {year: defaultVariables.mid_year})}</span>
			</span>

			<span
				className={
					`overview-small-text uk-width-1-1 top_margin_5  ${trendData.hide_since_AT} single-trend-detail`
				}
				id="txtSinceAT"
			>
				<span template-mif-string-id="196">{t("Absolute trend since {{year}}", {year: defaultVariables.earliest_year})}</span>
			</span>
		</div>
	);
};
