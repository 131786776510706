import React from "react";
import { DataTableSettingsPanel, ScatterChartPanel, BarChartPanel, SignificantChangesPanel, AbsoluteTrendBarPanel } from "../";

export default ({ urlParams, dispatch, state }) => {
	switch (urlParams.view) {
		case "table": {
			if (urlParams.subview === "drivers") {
				return null;
			}
			if (urlParams.subview === "changes") {
				return <SignificantChangesPanel />;
			}
			return <DataTableSettingsPanel urlParams={urlParams} dispatch={dispatch} />;
		}
		case "graph": {
			if (urlParams.subview === "absoluteTrend" && urlParams.option === "bar") {
				return <AbsoluteTrendBarPanel urlParams={urlParams} dispatch={dispatch} state={state} />;
			}
			if (urlParams.option === "scatter") {
				return <ScatterChartPanel urlParams={urlParams} dispatch={dispatch} />;
			}
			if (urlParams.option === "bar") {
				return <BarChartPanel urlParams={urlParams} dispatch={dispatch} state={state} />;
			}
		}
		default: {
			return null;
		}
	}
};
