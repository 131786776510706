import React from "react";
import styles from "../../styles.css";
import measureGroupBarStyles from "./styles.css";

import { isAllChildrensSelected, isSearchedPhrase } from "../../utils";
import {useTranslation} from "react-i18next";

const QuickSelectButtons = ({
	subjectColor,
	subjectId,
	subjectCounters,
	subject,
	selectedSubjects,
	addSelectedSubject,
	removeSelectedSubject,
	expandedMeasureBars,
	setExpandedMeasureBars,
	nestedListsToShow,
	showNestedLists,
}) => {
	let allSubCategories = [];
	let allIndicators = [];
	let allSubIndicators = [];
	const {t} = useTranslation();

	const getDependantChildrens = (list) => {
		list?.childrens?.forEach((child) => {
			if (child.childrens) {
				getDependantChildrens(child);
			}
			if (child.itemType === "SubCategories") {
				allSubCategories.push(child.id);
			}
			if (child.itemType === "Indicators") {
				allIndicators.push(child.id);
			}
			if (child.itemType === "SubIndicators") {
				allSubIndicators.push(child.id);
			}
		});
	};

	getDependantChildrens(subject);

	return (
		<>
			<div
				className={`${measureGroupBarStyles.selectionCounter}`}
				onClick={() => {
					const listOfSubjectsToExpand = [];
					const getDependantChildrens = (list) => {
						list.childrens.forEach((child) => {
							if (child.childrens) {
								getDependantChildrens(child);
							}
							if (selectedSubjects.includes(child.id) && !listOfSubjectsToExpand.includes(child.parent)) {
								listOfSubjectsToExpand.push(child.parent);
							}
						});
					};

					getDependantChildrens(subject);

					listOfSubjectsToExpand.push(subject.id);
					if (!expandedMeasureBars.includes(subject.id)) {
						setExpandedMeasureBars([...expandedMeasureBars, subject.id]);
						showNestedLists([...nestedListsToShow, ...listOfSubjectsToExpand]);
					} else {
						const collapseExpandedLists = nestedListsToShow.filter(
							(subjectId) => !listOfSubjectsToExpand.includes(subjectId)
						);
						setExpandedMeasureBars(expandedMeasureBars.filter((bar) => bar !== subject.id));
						showNestedLists(collapseExpandedLists);
					}
				}}
				uk-tooltip={t("Click to show selected measures")}
				style={{
					background: `rgba(${subjectColor}, 1)`,
				}}
			>
				{subjectCounters[subjectId]}
			</div>
			{subject.id === 'CITVOICE' ? <span uk-icon="info" onClick={
				() => {
					const modal = UIkit.modal("#modalCitvoiceInfo");
					modal.center = true;
					modal.show();
				}
			} className={`${measureGroupBarStyles.citvoiceInfoIcon} uk-icon-information`}></span> : null}
			<div className={measureGroupBarStyles.filler} />
			<div className={measureGroupBarStyles.measureQuick}>
				<ul className={`${measureGroupBarStyles.quickSelectionButtonsWrapper} uk-visible@m`}>
					<li className={measureGroupBarStyles.listItem}>
						<a
							onClick={(e) => {
								e.preventDefault();
								if (isAllChildrensSelected(allSubCategories, selectedSubjects)) {
									removeSelectedSubject({
										subjectsGroup: allSubCategories,
										selectedSubjects,
									});
								} else {
									addSelectedSubject({
										subjectsGroup: allSubCategories,
										selectedSubjects,
									});
								}
							}}
							href="#"
							style={
								isAllChildrensSelected(allSubCategories, selectedSubjects)
									? {
											background: `rgba(${subjectColor}, 0.25)`,
											border: `1px solid rgb(${subjectColor})`,
									  }
									: null
							}
							className={`${measureGroupBarStyles.allLink} ${
								isAllChildrensSelected(allSubCategories, selectedSubjects)
									? `${measureGroupBarStyles.selectedAll} ${measureGroupBarStyles.selected}`
									: ""
							}`}
						>
							{subject.isCV ? t("All Sub-Sections") : t("All Sub-Categories")}
						</a>
					</li>
					<li className={`${measureGroupBarStyles.separator} ${measureGroupBarStyles.listItem}`}>|</li>
					<li className={measureGroupBarStyles.listItem}>
						<a
							onClick={(e) => {
								e.preventDefault();
								if (isAllChildrensSelected(allIndicators, selectedSubjects)) {
									removeSelectedSubject({
										subjectsGroup: allIndicators,
										selectedSubjects,
									});
								} else {
									addSelectedSubject({
										subjectsGroup: allIndicators,
										selectedSubjects,
									});
								}
							}}
							href="#"
							className={`${measureGroupBarStyles.allLink} ${
								isAllChildrensSelected(allIndicators, selectedSubjects)
									? `${measureGroupBarStyles.selectedAll} ${measureGroupBarStyles.selected}`
									: ""
							}`}
							style={
								isAllChildrensSelected(allIndicators, selectedSubjects)
									? {
											background: `rgba(${subjectColor}, 0.25)`,
											border: `1px solid rgb(${subjectColor})`,
									  }
									: null
							}
						>
							{t("All Indicators")}
						</a>
					</li>
					<li className={`${measureGroupBarStyles.separator} ${measureGroupBarStyles.listItem}`}>|</li>
					<li className={measureGroupBarStyles.listItem}>
						<a
							onClick={(e) => {
								e.preventDefault();
								if (isAllChildrensSelected(allSubIndicators, selectedSubjects)) {
									const barsToFold = nestedListsToShow.filter(
										(subject) => !allIndicators.includes(subject)
									);

									showNestedLists(barsToFold);
									removeSelectedSubject({
										subjectsGroup: allSubIndicators,
										selectedSubjects,
									});
								} else {
									const barsToExpand = [];
									allIndicators.forEach((indicator) => {
										if (!nestedListsToShow.includes(indicator)) {
											barsToExpand.push(indicator);
										}
									});
									showNestedLists([...nestedListsToShow, ...barsToExpand]);
									addSelectedSubject({
										subjectsGroup: allSubIndicators,
										selectedSubjects,
									});
								}
							}}
							href="#"
							className={`${measureGroupBarStyles.allLink} ${
								isAllChildrensSelected(allSubIndicators, selectedSubjects)
									? `${measureGroupBarStyles.selectedAll} ${measureGroupBarStyles.selected}`
									: ""
							}`}
							style={
								isAllChildrensSelected(allSubIndicators, selectedSubjects)
									? {
											background: `rgba(${subjectColor}, 0.25)`,
											border: `1px solid rgb(${subjectColor})`,
									  }
									: null
							}
						>
							{t("All Sub-Indicators")}
						</a>
					</li>
				</ul>
			</div>
		</>
	);
};

export default ({
	subject,
	isSimplifiedView,
	selectedSubjects,
	onSubjectSelect,
	queryParams,
	nestedListsToShow,
	showNestedLists,
	subjectCounters,
	selectedGroupsOfSubjects,
	onSelectAllSubjects,
	addSelectedSubject,
	removeSelectedSubject,
	expandedMeasureBars,
	setExpandedMeasureBars,
	searchPhrase,
	onSubjectChange,
}) => {
	if (
		isSearchedPhrase({
			subjectName: subject.fullName,
			phrase: searchPhrase,
		}) &&
		!nestedListsToShow.includes(subject.topLevelParent)
	) {
		nestedListsToShow.push(subject.topLevelParent);
	}

	let disableExpandButton = false;

	let subjectsGroup = [];

	const getSubjectChildrens = (childrens) => {
		childrens.forEach((child) => {
			if (child.childrens) {
				getSubjectChildrens(child.childrens);
			}
			subjectsGroup.push(child.id);
		});
	};

	subject.childrens ? getSubjectChildrens(subject.childrens) : (subjectWithNoChildren = true);

	subjectsGroup.forEach((children) => {
		if (
			isSearchedPhrase({
				subjectName: children,
				phrase: searchPhrase,
			})
		) {
			disableExpandButton = true;
		}
	});

	return (
		<div className={styles.measureGroup}>
			<div className={styles.measureCollapse}>
				<img className={styles.measureIcon} src={`/img/icons/${subject.id}.svg`} alt={subject.fullName} />
				<h5
					className={`${measureGroupBarStyles.measureName} ${
						!isSimplifiedView && selectedSubjects.includes(subject.id)
							? measureGroupBarStyles.selectedItem
							: ""
					}`}
				>
					<a
						href={`/measures/${subject.id.toLowerCase()}.html`}
						className={`${
							isSearchedPhrase({
								subjectName: subject.fullName,
								phrase: searchPhrase,
							})
								? styles.highlightSearchedWord
								: ""
						} ${styles.level0} ${
							!isSimplifiedView && selectedSubjects.includes(subject.id)
								? measureGroupBarStyles.selected
								: ""
						}
						${isSimplifiedView ? measureGroupBarStyles.measureTitle : ""}`}
						style={
							!isSimplifiedView && selectedSubjects.includes(subject.id)
								? {
										background: `rgba(${subject.subjectColor}, 0.25)`,
										border: `1px solid rgb(${subject.subjectColor})`,
								  }
								: null
						}
						onClick={(e) => {
							if (isSimplifiedView && onSubjectChange) {
								e.preventDefault();
								onSubjectChange(subject.id);
							}
							if (!isSimplifiedView) {
								e.preventDefault();
								onSubjectSelect(subject.id);
							}
						}}
					>
						{subject.fullName}
					</a>
				</h5>
				{!isSimplifiedView ? (
					<QuickSelectButtons
						subjectCounters={subjectCounters}
						subjectId={subject.id}
						subjectColor={subject.subjectColor}
						subjectChildrens={subject.childrens}
						selectedGroupsOfSubjects={selectedGroupsOfSubjects}
						onSelectAllSubjects={onSelectAllSubjects}
						subject={subject}
						selectedSubjects={selectedSubjects}
						addSelectedSubject={addSelectedSubject}
						removeSelectedSubject={removeSelectedSubject}
						expandedMeasureBars={expandedMeasureBars}
						setExpandedMeasureBars={setExpandedMeasureBars}
						nestedListsToShow={nestedListsToShow}
						showNestedLists={showNestedLists}
					/>
				) : null}

				{!nestedListsToShow.includes(subject.id) ? (
					<span
						className={`plus uk-icon ${styles.expandIcon} ${
							isSimplifiedView ? measureGroupBarStyles.floatRight : ""
						}`}
						uk-icon="icon:plus"
						onClick={() => {
							showNestedLists((displayedRows) => [...displayedRows, subject.id]);
						}}
					></span>
				) : (
					<span
						className={`minus uk-icon
						${styles.expandIcon}
						${isSimplifiedView ? measureGroupBarStyles.floatRight : ""}
						${disableExpandButton ? measureGroupBarStyles.disable : ""}
						`}
						uk-icon="icon:minus"
						onClick={() => {
							if (disableExpandButton) {
								return;
							}
							showNestedLists((displayedRows) => displayedRows.filter((row) => row !== subject.id));
						}}
					></span>
				)}
			</div>
		</div>
	);
};
