// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.range2Wrapper--308Cm {\n    opacity: 0;\n    width: calc(49% - 8rem);\n}\n[dir=ltr] .range2Wrapper--308Cm {\n    animation: fullWidth--11u1U 0.2s forwards;\n    margin-left: 30px;\n}\n[dir=rtl] .range2Wrapper--308Cm {\n    animation: fullWidth--11u1U 0.2s forwards;\n    margin-right: 30px;\n}\n\n.resetButtonWrapper--3NrWd {\n    display: flex;\n    justify-content: flex-end;\n}\n\n[dir] .resetButton--1fiGu {\n    border-radius: 5px;\n}\n\n@keyframes fullWidth--11u1U {\n    from {\n        opacity: 0;\n    }\n    to {\n        opacity: 1;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"range2Wrapper": "range2Wrapper--308Cm",
	"fullWidth": "fullWidth--11u1U",
	"resetButtonWrapper": "resetButtonWrapper--3NrWd",
	"resetButton": "resetButton--1fiGu"
};
module.exports = exports;
